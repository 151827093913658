import { IWorkspaceSnapshot } from 'src/store/models/workspace'
import { IGoogleDriveFile } from 'src/store/models/googleDrive'
import { Observable } from 'apollo-link'
import { History } from 'history'
import { omit } from 'lodash'
import makeInspectable from 'mobx-devtools-mst'
import {
  cast,
  destroy,
  detach,
  flow,
  Instance,
  resolveIdentifier,
  types
} from 'mobx-state-tree'
import * as moment from 'moment'
import { toast } from 'react-toastify'
import { ApiUser } from 'src/api/gql'
import { SharedSchemaStatus } from 'src/api/sharedSchema'
import Toast from 'src/components/Toast'
import { isDevelopment, storage } from 'src/config'
import { EventType, sendAmplitudeData } from 'src/helpers/analytics'
import navigateToSchemaPage from 'src/helpers/navigateToSchemaPage'
import { fromApiToEditorNodes } from 'src/helpers/nodesMapping'
import {
  INodeSnapshot,
  INode
} from 'src/pages/SchemaPage/components/Editor/editorStore'
import UI from 'src/store/ui'

import API from '../api'
import { getAllAttachments } from '../api/schema'
import {
  googleDriveFolderStorageKey,
  LAST_OPEN_SCHEMA_ID_STORAGE_KEY
} from '../config'
import { IEditorStore } from '../pages/SchemaPage/components/Editor/editorStore'
import Binder from './models/binder'
import GoogleDriveFile from './models/googleDrive'
import Mention from './models/mention'
import { DummySchema, ISchema, ISchemaSnapshot } from './models/schema'
import User from './models/user'
import Workspace from './models/workspace'
import { ApiTemplate } from 'src/api/template'
import { isElectron, calibrateFiles } from 'src/helpers/electron'

const Store = types
  .model('Store', {
    workspace: types.maybeNull(Workspace),
    user: types.maybeNull(User),
    binder: types.optional(Binder, {}),
    currentSchema: types.maybeNull(DummySchema),
    currentGDriveId: types.maybeNull(types.string),
    syncPending: types.optional(types.boolean, false),
    isSignedInGoogle: false,
    googleDriveFolder: types.maybeNull(types.string),
    isInitialized: false,
    mention: types.optional(Mention, {}),
    // TODO: check why volatile doesn't work on update
    downloadingFiles: types.array(types.string)
  })
  .volatile(self => ({
    editorStore: undefined as IEditorStore | undefined,
    // internal clipboard
    clipboard: [] as INode[],
    // global clipboard
    clipboardText: undefined as string | undefined,
    nodesToDelete: [] as Array<{ nodeReference: string; schemaId: string }>,
    setSubscription: undefined as Function | undefined,
    logout: () => {},
    sharedNodeRef: undefined as undefined | string,
    templates: null as ApiTemplate[] | null | undefined,
    isElectronSubscriptionOn: false
  }))
  .views(self => ({
    get currentGDrive(): IGoogleDriveFile | undefined {
      if (!self.currentGDriveId) return
      return resolveIdentifier(GoogleDriveFile, self, self.currentGDriveId)
    }
  }))
  .actions(self => ({
    initialize: flow<void, []>(function* initialize() {
      const user: ApiUser = yield storage.getItem('schema_user')
      if (!user) return

      API.statistic.createStatistic({
        lastSignIn: new Date()
      })

      const [workspace, attachments] = (yield Promise.all([
        API.workspace.getWorkspaceById(user.workspace.id),
        getAllAttachments(),
        self.binder.initialize()
      ])) as [IWorkspaceSnapshot, any, void]

      self.user = cast(omit(user, 'workspace'))
      self.workspace = cast(workspace)
      self.googleDriveFolder = localStorage.getItem(googleDriveFolderStorageKey)

      sendAmplitudeData(EventType.Signin)
      self.mention.data = attachments
      if (isElectron) {
        yield API.node.createAttachmentSubscription()
        yield API.schema.archiveSchemaSubscription()
        yield API.schema.electronUpdateSchemaSubscription()
      }
      self.isInitialized = true
    }),
    update: <T extends keyof typeof self>(
      field: T,
      value: typeof self[T]
    ): void => {
      self[field] = value
      if (field === 'isSignedInGoogle' && value === true) {
        self.binder.initGoogleDrive()
        if (UI.connectGDrive.isOpen) {
          UI.connectGDrive.close()
          UI.selectGoogleDriveFolderModal.open()
        }
      }
    }
  }))
  .actions(self => ({
    resetSchema: (): void => {
      if (self.currentSchema) {
        detach(self.currentSchema)
        self.currentSchema = null
        self.editorStore = undefined
      }
    },
    getSchemaById: flow<ISchema | null, [string]>(function* getSchemaById(id) {
      const schema: ISchema | null = yield API.schema.getSchemaById(id)
      self.currentSchema = schema
      localStorage.setItem(LAST_OPEN_SCHEMA_ID_STORAGE_KEY, id)
      if (schema) calibrateFiles(schema)
      return schema
    }),
    getSharedSchemaById: flow<ISchema, [string]>(function* getSharedSchemaById(
      id
    ) {
      const schema = yield API.sharedSchema.getSharedSchema(id)
      self.currentSchema = schema
      return schema
    }),
    createSchema: flow<ISchemaSnapshot | undefined, [boolean?]>(
      function* createSchema(isPrivate = true) {
        try {
          const schema: ISchemaSnapshot = yield API.schema.createSchema(
            isPrivate
          )
          return schema
        } catch (err) {
          toast.error(err.message)
          console.log('ERROR: createSchema: ', err)
          return
        }
      }
    ),
    updateSchema: flow<
      ISchemaSnapshot | undefined,
      [
        Partial<ISchemaSnapshot>,
        {
          focusedElementId?: string
          caretPosition?: number
          _destroy?: boolean
        }?,
        string?
      ]
    >(function* updateSchema(schema, collaborator, token) {
      try {
        return yield API.schema.updateSchema(schema, collaborator, token)
      } catch (err) {
        console.log('ERROR: updateSchema: ', err)
        // TODO: it may get spammy
        // better to have an error state
        Toast(err.message, {
          autoClose: 3000,
          position: Toast.POSITION.TOP_RIGHT
        })
      }
    }),
    // this is only used in rare cases like updating isEditing state of schema, which we can not wait to receive it's returned value.
    dangerouslySendSchemaUpdate: (
      schema: Partial<ISchemaSnapshot>,
      collaborator?: {
        nodeId?: number
        caretPosition?: number
        _destroy?: boolean
      }
    ): void => {
      API.schema.updateSchema(schema, collaborator)
    },
    attachUrl: flow<string, [string, string]>(function* attachUrl(url, nodeId) {
      return yield API.node.attachUrl(url, self.currentSchema!.id, nodeId)
    }),
    deleteAttachment: flow<void, [string]>(function* deleteAttachment(nodeId) {
      yield API.node.deleteAttachment(nodeId)
    }),
    createInvitation: flow<void, [string, string?]>(function* createInvitation(
      email,
      name
    ) {
      yield API.invitation.createInvitation(email, name)
    }),
    archiveSchema: flow<void, [string]>(function* archiveSchema(schemaId) {
      try {
        yield API.schema.archiveSchema(schemaId)
      } catch (err) {
        console.log('ERROR: archiveSchema: ', err)
      }
    }),
    unarchiveSchema: flow<void, [string]>(function* unarchiveSchema(schemaId) {
      try {
        yield API.schema.unarchiveSchema(schemaId)
        self.binder.initialize()
        self.binder.getArchived()
      } catch (err) {
        console.log('ERROR: unarchiveSchema: ', err)
      }
    }),
    deleteSchema: flow<void, [string]>(function* deleteSchema(schemaId) {
      try {
        yield API.schema.deleteSchema(schemaId)
        const schema =
          self.binder.archived.private.find(s => s.id === schemaId) ||
          self.binder.archived.team.find(s => s.id === schemaId)
        if (schema) {
          destroy(schema)
        } else {
          self.binder.initialize()
        }
      } catch (err) {
        console.log('ERROR: deleteSchema: ', err)
      }
    }),
    updateSchemaSubscription: flow(function* updateSchemaSubscription(
      id: string,
      CUDNodes: (
        payload: {
          created: INodeSnapshot[]
          updated: INodeSnapshot[]
          deleted: { id: string }[]
        },
        updatedByUserId: string,
        token?: string
      ) => void
    ) {
      try {
        const { currentSchema } = self
        if (!currentSchema) {
          return
        }
        const data: Observable<any> = yield API.schema.updateSchemaPayload(id)
        return data.subscribe(value => {
          const schema = value.data.UpdateSchemaPayload.payload.schema

          if (
            schema.nodes.created ||
            schema.nodes.updated ||
            schema.nodes.deleted
          ) {
            CUDNodes(
              {
                created: fromApiToEditorNodes(schema.nodes.created),
                updated: fromApiToEditorNodes(schema.nodes.updated),
                deleted: schema.nodes.deleted.map(
                  (d: { reference: string }) => ({
                    id: d.reference
                  })
                )
              },
              schema.updatedByUserId,
              value.data.UpdateSchemaPayload.payload.token
            )
          }

          currentSchema.update('title', schema.title)

          currentSchema.update(
            'collaborators',
            value.data.UpdateSchemaPayload.payload.collaborators
          )

          if (
            moment(schema.updatedAt).diff(
              moment(currentSchema.updatedAt),
              'minute'
            ) >= 1
          ) {
            currentSchema.update('updatedAt', schema.updatedAt)
          }

          currentSchema.update('updatedByUserId', schema.updatedByUserId)
        })
      } catch (err) {
        console.log('ERROR: updateSchemaSubscription', err)
        return
      }
    }),
    updateShareableLinkSubscription: flow(
      function* updateShareableLinkSubscription(schemaId: string) {
        if (schemaId !== self.currentSchema?.id) return
        try {
          const data: Observable<any> = yield API.sharedSchema.upsertShareableLinkPayload(
            schemaId
          )
          return data.subscribe(value => {
            const status = value.data.UpsertShareableLinkPayload.payload
              .status as SharedSchemaStatus
            if (status === 'READ' && !store.currentSchema?.readonly) {
              store.currentSchema?.update('readonly', true)
              toast.warn('The schema has been set to readonly by the sender.')
            } else if (
              status === 'READWRITE' &&
              store.currentSchema?.readonly
            ) {
              store.currentSchema?.update('readonly', false)
              toast.info('The schema has been set to editable by the sender.')
            } else if (status === 'DISABLED') {
              store.editorStore?.update('nodes', cast([]))
              toast.warn('The schema sharing has been disabled.')
            }
          })
        } catch (e) {
          console.log('ERROR: updateShareableLinkSubscription', e)
          return
        }
      }
    ),
    updateSharedSchemaSubscription: flow(
      function* updateSharedSchemaSubscription(id: string) {
        try {
          const data: Observable<any> = yield API.sharedSchema.updateSharedSchemaPayload(
            id
          )
          return data.subscribe(value => {
            if (
              value.data.UpdateSharedSchemaPayload.payload.sharedSchema.id !==
              self.currentSchema?.sharedSchemaId
            ) {
              return
            }

            const status = value.data.UpdateSharedSchemaPayload.payload
              .sharedSchema.status as SharedSchemaStatus
            if (status === 'READ' && !store.currentSchema?.readonly) {
              store.currentSchema?.update('readonly', true)
              toast.warn('The schema has been set to readonly by the sender.')
            } else if (
              status === 'READWRITE' &&
              store.currentSchema?.readonly
            ) {
              store.currentSchema?.update('readonly', false)
              toast.info('The schema has been set to editable by the sender.')
            } else if (status === 'DISABLED') {
              store.editorStore?.update('nodes', cast([]))
              toast.warn('The schema sharing has been disabled.')
            }
          })
        } catch (e) {
          console.log('ERROR: updateSharedSchemaSubscription', e)
          return
        }
      }
    ),
    deleteSharedSchemaSubscription: flow(
      function* deleteSharedSchemaSubscription(id: string, history: History) {
        try {
          const data: Observable<any> = yield API.sharedSchema.deleteSharedSchemaPayload(
            id
          )
          return data.subscribe(value => {
            if (
              value.data.DeleteSharedSchemaPayload.payload.sharedSchema.id ===
              self.currentSchema?.sharedSchemaId
            ) {
              toast.warn(
                `Schema "${
                  self.currentSchema?.title || 'Untitled'
                }" sharing has been disabled.`
              )
              const deleted = store.binder.findSchemaById(
                self.currentSchema?.id!
              )
              deleted && store.binder.destroy(deleted)
              navigateToSchemaPage(history)
            }
          })
        } catch (e) {
          console.log('ERROR: deleteSharedSchemaSubscription', e)
          return
        }
      }
    ),
    setSyncPending: (pending: boolean) => (self.syncPending = pending),
    rehydrateMentions: flow<void, []>(function* () {
      self.mention.update('data', yield getAllAttachments())
    }),
    addDownloadingFile: (ref: string) => self.downloadingFiles.push(ref),
    removeDownloadingFile: (ref: string) =>
      (self.downloadingFiles = cast(
        self.downloadingFiles.filter(i => i !== ref)
      ))
  }))

const store = Store.create()
isDevelopment && makeInspectable(store)

export default store
export interface IStore extends Instance<typeof Store> {}
