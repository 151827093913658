import { resolveIdentifier, types } from 'mobx-state-tree'
import GoogleDriveFile from 'src/store/models/googleDrive'
import store from 'src/store'

const selectGoogleDrivestore = types
  .model('SelectGoogDriveStore', {
    currentFolderId: 'root',
    selectedFileId: types.maybeNull(types.string)
  })
  .views(self => ({
    get currentFolder() {
      if (!self.currentFolderId) return
      return resolveIdentifier(GoogleDriveFile, store, self.currentFolderId)
    },
    get selectedFile() {
      if (!self.selectedFileId) return
      return resolveIdentifier(GoogleDriveFile, store, self.selectedFileId)
    }
  }))
  .actions(self => ({
    update: <T extends keyof typeof self>(field: T, value: typeof self[T]) => {
      self[field] = value
    }
  }))

const SelectGoogleDriveStore = selectGoogleDrivestore.create()
export default SelectGoogleDriveStore
