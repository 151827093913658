import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { isDevelopment } from 'src/config'

import App from './App'
import { initAnalytics } from './helpers/analytics'

initAnalytics()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').then(function () {
    console.log('Service Worker Registered')
  })
}

if (!isDevelopment)
  Sentry.init({
    dsn: 'https://d81335a106a24bddaac87d6d7ab34d7d@sentry.io/1869741'
  })

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement)
