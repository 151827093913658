import Toast from 'src/components/Toast'
import { getApolloClient } from 'src/config/apolloClient'
import { EventType, sendAmplitudeData } from 'src/helpers/analytics'

import { ApiInvitation, createInvitationMutation } from './gql'

export const createInvitation = async (
  email: string,
  name?: string
): Promise<ApiInvitation | undefined> => {
  try {
    const client = await getApolloClient()
    const res = await client.mutate<{}>({
      mutation: createInvitationMutation,
      variables: {
        input: {
          email,
          name
        }
      }
    })
    Toast(`You've succesffully invited ${email}.`, {
      autoClose: 3000,
      position: Toast.POSITION.TOP_RIGHT,
      hideProgressBar: true
    })
    sendAmplitudeData(EventType.UserInvited, { email: email })
    return res.data!.createInvitation.invitation
  } catch (err) {
    console.log('ERROR: createInvitation: ', err)
    Toast(err.message, {
      autoClose: 3000,
      position: Toast.POSITION.TOP_RIGHT
    })
    return
  }
}
