import { isElectron } from './../helpers/electron'
import { clientHost } from 'src/config'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink as ApolloLink2
} from 'apollo-boost'
import { split, ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { ServerError } from 'apollo-link-http-common'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { Auth } from 'aws-amplify'
import * as jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { sendTokenToElectron } from 'src/helpers/electron'
import { RetryLink } from 'apollo-link-retry'
import Cookies from 'js-cookie'

import { apiLocation, serverHost } from './'
import store from 'src/store'
import { SubscriptionClient } from 'subscriptions-transport-ws'

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (
      networkError &&
      (networkError as ServerError).statusCode &&
      (networkError as ServerError).statusCode === 401
    ) {
      refreshToken()
    }
  }
)

let client: ApolloClient<any>

export const getApolloClient = async () => client || (await clientFactory())

export const initApolloClient = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const token = cognitoUser.signInUserSession.idToken.jwtToken
    await tokenHandler(token)

    const tokenPayload = jwtDecode<{ exp: number }>(token)

    const timeout =
      tokenPayload.exp * 1000 - new Date().getTime() - 10 * 60 * 1000

    // console.log('timeout........', timeout);
    if (timeout < 0) {
      await refreshTokenInterval()
    } else {
      setTimeout(refreshTokenInterval, timeout)
    }
  } catch (e) {
    console.error(e)
  }
}

export const refreshTokenInterval = async () => {
  await refreshToken()
  setInterval(async () => {
    await refreshToken()
  }, 50 * 60 * 1000)
}

let _token: string

export const getToken = () => _token

export const tokenHandler = async (token: string, reInitElectron = false) => {
  _token = token
  await clientFactory(token)
  sendTokenToElectron(token, reInitElectron)
  if (!isElectron) {
    Cookies.set('cognito-token', token, {
      domain: new URL(clientHost).hostname,
      secure: true,
      sameSite: 'lax'
    })
  }
}

export const refreshToken = async (reInitElectron = false) => {
  try {
    const currentSession = await Auth.currentSession()
    const user = await Auth.currentAuthenticatedUser()
    return await new Promise<string>((resolve, reject) =>
      user.refreshSession(
        currentSession.getRefreshToken(),
        async (e: any, session: CognitoUserSession) => {
          if (e) {
            console.error(e)
            toast.error('Unable to refresh the token. Please refresh the page.')
            return
          }
          console.log('Token refreshed')
          const token = session.getIdToken().getJwtToken()
          await tokenHandler(token, reInitElectron)
          store.setSubscription && store.setSubscription()
          resolve(token)
        }
      )
    )
  } catch (e) {
    console.error(e)
    toast.error(e.message)
    return
  }
}

export const clientFactory = async (authToken?: string) => {
  let token = authToken
  if (!token) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (!cognitoUser) {
        throw new Error('Cognito User not found.')
      }
      token = cognitoUser.signInUserSession.idToken.jwtToken
    } catch (e) {
      console.log('cognito user not registered.')
      token = window['sharedSchemaToken']
      window['anonymousUser'] = true
    }
  }

  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_WSLINK}`,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: {
        authorization: 'Bearer ' + token
      }
    }
  })

  const subscriptionClient: SubscriptionClient = wsLink['subscriptionClient']

  // "lazy: true" works. But just in case
  // https://github.com/apollographql/subscriptions-transport-ws/issues/381
  subscriptionClient['maxConnectTimeGenerator'].duration = () =>
    subscriptionClient['maxConnectTimeGenerator'].max

  subscriptionClient.on('connecting', () => {
    console.log('connecting')
  })

  subscriptionClient.on('connected', () => {
    store.update('isElectronSubscriptionOn', true)
    console.log('connected')
  })

  subscriptionClient.on('reconnecting', () => {
    console.log('reconnecting')
  })

  subscriptionClient.on('reconnected', () => {
    store.update('isElectronSubscriptionOn', true)
    console.log('reconnected')
  })

  subscriptionClient.on('disconnected', () => {
    store.update('isElectronSubscriptionOn', false)
    console.log('disconnected')
  })

  subscriptionClient.on('error', (e: any) => {
    console.log('WS ERROR: ', e)
  })

  const retryLink = new RetryLink()

  const links = ApolloLink.from([
    errorLink,
    retryLink,
    createUploadLink({
      uri: `${serverHost}${apiLocation}`,
      headers: {
        authorization: `Bearer ${token}`
      }
    }) as ApolloLink
  ])

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as any
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    links
  ) as ApolloLink2

  client = new ApolloClient({
    link,
    cache: new InMemoryCache()
  })
  return client
}
