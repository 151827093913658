export default {
  tutorial: {
    width: 510,
    titleFontSize: 32,
    bodyFontSize: 16,
    subtitleFontSize: 25,
    body2FontSize: 18,
    shortcutFontSize: 13,
    shortcutColors: '#655D5D',
    BoldFontWeight: 700,
    titleFontWeight: 700,
    orangeColor: '#F37F78',
    prevColor: '#C9C9C9',
    skipColor: '#C9C9C9',
    bodyColor: '#655D5D'
  },
  spacingUnit: 10,
  schemaPage: {
    topBarHeight: 50,
    sidebarBottom: 70
  },
  searchInput: {
    height: 45,
    width: 260,
    borderRadius: 8,
    borderWidth: 2
  },
  nodes: {
    padding: 3
  },
  modal: {
    topBarHeight: 68
  },
  fontWeights: {
    light: 400,
    normal: 500,
    bold: 300,
    black: 600,
    heavy: 700,
    extreme: 800
  },
  delays: {
    analyticEventDelay: 2 * 60 * 1000
  },
  placeholder: {
    fullTextWidth: 764,
    singleWordWidth: 712
  },
  tooltips: {
    topBar: {
      menuIcon: 'Open Side Bar',
      arrowIcon: 'Close Side Bar',
      shareButton: 'Share this Schema',
      searchButton: 'Search your Schemas',
      moreButton: 'Schema settings',
      helpButtonOpen: 'Open Crisp Chat',
      tutorialButtonClose: 'Close tutorial panel',
      archive: 'Archive this schema',
      delete: 'Delete current folder',
      collapseAll: 'Collapse all nodes',
      showThumbnail: 'Show Thumbnails',
      hideThumbnail: 'Hide Thumbnails',
      expandAll: 'Expand All nodes'
    },
    editor: {
      folderIcon: 'Open folder',
      folderTitleIcon: 'Change icon',
      dragHandler: 'Drag to move the node<br/>Click to show options',
      expandNode: 'Expand this node',
      collapseNode: 'Collapse this node',
      fileTag: 'Open file',
      addIcon: {
        emptyNode: 'Show node options',
        createNode: 'Create a new node'
      },
      sidebar: {
        folder: 'Create subfolder',
        attach: 'Upload a new file',
        drive: 'Attach from google drive'
      }
    },
    binder: {
      addSchema: 'Create new schema'
    }
  },
  mention: {
    itemHeight: 48,
    numberOfVisibleItems: 6
  }
}
