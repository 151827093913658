import { gql } from 'apollo-boost'

export const pageInfoFields = `
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
`

export interface PageInfo {
  startCursor: string
  endCursor: string
  hasNextPage: boolean
  hasPreviousPage: boolean
}

export const nodeFields = `
  id
  reference
  type
  text
  checked
  language
  fatherReference
  index
  icon
  collapsed
  attachmentUrl
  fileSize
  createdAt
  updatedAt
  editedAt
`

export type NodeTypeEnum =
  | 'BULLET_POINT'
  | 'PARAGRAPH'
  | 'H1'
  | 'H2'
  | 'H3'
  | 'CHECKBOX'
  | 'SUBPAGE'
  | 'CODE_SNIPPET'

export interface ApiNode {
  id: number
  reference: string
  type: NodeTypeEnum
  text: string
  checked: boolean
  language: string
  fatherReference: string
  attachmentUrl: string
  index: number
  icon: string | null
  collapsed: boolean
  createdAt: string
  updatedAt: string
  editedAt: number
  fileSize: number
  parentPage?: {
    parentPageReference: string | null
    relativePath: number[]
  } | null
}

export const schemaFields = `
  id
  title
  inline
  isPrivate
  archivedAt
  createdAt
  updatedAt
  updatedByUserId
  icon
  readonly
  sharedSchemaId
`

export const schemaFieldsMobile = `
  id
  title
  isEditing
  archivedAt
  createdAt
  updatedAt
  icon
  nodes {
    ${nodeFields}
  }
`

export interface ApiSchema {
  id: string
  title: string
  inline: boolean
  nodes: ApiNode[]
  subpages?: ApiNode[]
  isPrivate: boolean
  archivedAt: string | null
  createdAt: string
  updatedAt: string
  icon: string | null
  updatedByUserId: string
  readonly?: boolean | null
  sharedSchemaId?: string | null
}

export interface ApiGetSchemaByIdResult {
  schema: ApiSchema
}

export const getSchemaByIdQuery = gql`
  query getSchema($id: String!, $nodeRef: String) {
    schema(id: $id, nodeRef: $nodeRef) {
      ${schemaFields}
      nodes {
        ${nodeFields}
      }
    }
  }
`

export interface ApiAllSchemasResult {
  allSchemas: {
    pageInfo: PageInfo
    totalCount: number
    schema: Array<Omit<ApiSchema, 'nodes'>>
  }
}

export interface ApiAllSchemasResultMobile {
  allSchemas: {
    pageInfo: PageInfo
    totalCount: number
    schema: Array<ApiSchema>
  }
}

export const allSchemasQuery = gql`
  query allSchemas($where: SchemaWhereInput, $orderBy: SchemaOrderBy, $first: Int) {
    allSchemas(where: $where, orderBy: $orderBy, first: $first) {
      pageInfo {
        ${pageInfoFields}
      }
      totalCount
      schema {
        ${schemaFields}
        subpages {
          ${nodeFields}
          parentPage {
            parentPageReference
            relativePath
          }
        }
      }
    }
  }
`

export const allSchemasQueryMobile = gql`
  query allSchemas($where: SchemaWhereInput, $orderBy: SchemaOrderBy) {
    allSchemas(where: $where, orderBy: $orderBy) {
      pageInfo {
        ${pageInfoFields}
      }
      totalCount
      schema {
        ${schemaFieldsMobile}
      }
    }
  }
`

export interface ApiCreateSchemaResult {
  createSchema: {
    schema: ApiSchema
  }
}

export const createSchemaMutation = gql`
  mutation createSchema($input: CreateSchemaInput!) {
    createSchema(input: $input) {
      schema {
        ${schemaFields}
        nodes {
          ${nodeFields}
        }
      }
    }
  }
`

export interface ApiUpdateSchemaResult {
  updateSchema: {
    schema: Omit<ApiSchema, 'nodes'>
  }
}

export const updateSchemaMutation = gql`
  mutation updateSchema($input: UpdateSchemaInput!) {
    updateSchema(input: $input) {
      schema {
        ${schemaFields}
      }
    }
  }
`

export interface ApiDeleteSchemaResponse {
  deleteSchema: {
    schema: Omit<ApiSchema, 'nodes'>
  }
}

export const deleteSchemaMutation = gql`
  mutation deleteSchema($input: DeleteSchemaInput!) {
    deleteSchema(input: $input) {
      schema {
        ${schemaFields}
      }
    }
  }
`

export interface ApiArchiveSchemaResponse {
  archiveSchema: {
    schema: Omit<ApiSchema, 'nodes'>
  }
}

export const archiveSchemaMutation = gql`
  mutation archiveSchema($input: ArchiveSchemaInput!) {
    archiveSchema(input: $input) {
      schema {
        ${schemaFields}
      }
    }
  }
`

export interface ApiUnarchiveSchemaResponse {
  unarchiveSchema: {
    schema: Omit<ApiSchema, 'nodes'>
  }
}

export const unarchiveSchemaMutation = gql`
  mutation unarchiveSchema($input: UnarchiveSchemaInput!) {
    unarchiveSchema(input: $input) {
      schema {
        ${schemaFields}
      }
    }
  }
`

export const createNodeMutation = gql`
  mutation createNode($input: CreateNodeInput!) {
    createNode(input: $input) {
      node {
        ${nodeFields}
      }
    }
  }
`

export const updateNodeMutation = gql`
  mutation updateNode($input: UpdateNodeInput!) {
    updatenode(input: $input) {
      node {
        ${nodeFields}
      }
    }
  }
`

export const deleteNodeMutation = gql`
  mutation deleteNode($input: DeleteNodeInput!) {
    deletenode(input: $input) {
      node {
        ${nodeFields}
      }
    }
  }
`

export const duplicateAttachmentMutation = gql`
  mutation duplicateAttachment($input: DuplicateAttachmentInput!) {
    duplicateAttachment(input: $input) {
      url
    }
  }
`

export const createUrlAttachmentMutation = gql`
  mutation createUrlAttachment($input: CreateUrlAttachmentInput!) {
    createUrlAttachment(input: $input) {
      attachment {
        url
      }
      node {
        ${nodeFields}
      }
    }
  }
`

export const deleteAttachmentMutation = gql`
  mutation deleteAttachment($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      node {
        ${nodeFields}
      }
    }
  }
`

export const cognitoUserPoolFields = `
  id
  appClientId
  createdAt
  updatedAt  
`

export interface ApiCognitoUserPool {
  id: string
  appClientId: string
  createdAt: string
  updatedAt: string
}

export const workspaceFields = `
  id
  name
  createdAt
  updatedAt
  cognitoUserPool {
    ${cognitoUserPoolFields}
  }
  emailDigestEnabled
  timeZone
`

export interface ApiWorkspace {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  cognitoUserPool: ApiCognitoUserPool
  users?: ApiUser[]
  emailDigestEnabled: boolean
  timeZone: number
}

export const userFields = `
  id
  fullName
  email
  role
  createdAt
  updatedAt
  emailDigestEnabled
  tutorialSeen
  findOutAboutSchemaFrom
  describeWhatYouDo
  schemaUsage
  googleDriveFolderId
  showThumbnail
`

export interface ApiUser {
  id: string
  email: string
  fullName: string
  role: string
  createdAt: string
  updatedAt: string
  workspace: ApiWorkspace
  emailDigestEnabled: boolean
  tutorialSeen: boolean
  findOutAboutSchemaFrom: string
  describeWhatYouDo: string
  schemaUsage: string
  googleDriveFolderId: string | null
  showThumbnail: boolean
}

export const getUserByIdQuery = gql`
  query getUserById($id: String) {
    user(id: $id) {
      ${userFields}
      workspace {
        ${workspaceFields}
      }
    }
  }
`

export const allUsersQuery = gql`
  query allUsers($where: UserWhereInput, $orderBy: UserOrderBy) {
    allUsers(where: $where, orderBy: $orderBy) {
      user {
        ${userFields}
      }
    }
  }
`

export const createUserMutation = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ${userFields}
      }
    }
  }
`

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ${userFields}
        workspace {
          ${workspaceFields}
        }
      }
    }
  }
`

export const disableUserMutation = gql`
  mutation disableUser($input: DisableUserInput!) {
    disableUser(input: $input) {
      user {
        ${userFields}
      }
    }
  }
`

export const invitationFields = `
  id
  email
  createdAt
  updatedAt
`

export interface ApiInvitation {
  id: string
  email: string
  createdAt: string
  updatedAt: string
  workspace: ApiWorkspace
  inviter: Omit<ApiUser, 'workspace'>
}

export const createInvitationMutation = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        ${invitationFields}
        workspace {
          ${workspaceFields}
        }
        inviter {
          ${userFields}
        }
      }
    }
  }
`

export interface GetWorkspaceResponse {
  workspace: Omit<ApiWorkspace, 'cognitoUserPool'> & {
    users: Omit<ApiUser, 'workspace'>
  }
}

export const getWorkspaceByIdQuery = gql`
  query getWorkspaceById($id: String!) {
    workspace(id: $id) {
      id
      name
      createdAt
      updatedAt
      users {
        ${userFields}
      }
    }
  }
`

export const allWorkspacesQuery = gql`
  query allWorkspaces($where: WorkspaceWhereInput, $orderBy: WorkspaceOrderBy) {
    allWorkspaces(where: $where, orderBy: $orderBy) {
      workspace {
        ${workspaceFields}
        users {
          ${userFields}
        }
      }
    }
  }
`

export const createWorkspaceMutation = gql`
  mutation createWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      workspace {
        ${workspaceFields}
      }
    }
  }
`

export const updateWorkspaceMutation = gql`
  mutation updateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        ${workspaceFields}
      }
    }
  }
`

export interface ApiCollaborator {
  id: string
  fullName: string
  email: string
  focusedElementId: string
  caretPosition: number
  updatedAt: string
}

const collaboratorFields = `
  id
  fullName
  email
  focusedElementId
  caretPosition
  updatedAt
`

export const updateSchemaSubscription = gql`
  subscription updateSchemaSubscription($id: ID) {
    UpdateSchemaPayload(id: $id) {
      payload {
        schema {
          ${schemaFields}
          updatedByUserId
          nodes {
            created {
              ${nodeFields}
            }
            updated {
              ${nodeFields}
            }
            deleted {
              reference
            }
          }
        }
        collaborators {
          ${collaboratorFields}
        }
        token
      }
    }
  }
`

export interface ApiSearchSchemasResponse {
  pageInfo: PageInfo
  totalCount: number
  schemas: ApiSchemaSearchItem[]
}

export interface ApiSchemaSearchItem {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  nodes: ApiNodeSearchItem[]
}

export interface ApiNodeSearchItem {
  id: string
  reference: string
  text: string
  sanitizedText: string
  updatedAt: string
}

const searchSchemaFields = `
  id
  title
  createdAt
  nodes {
    id
    reference
    text
    sanitizedText
    updatedAt
  }
`

export const searchSchemasQuery = gql`
  query searchSchemas($where: SearchSchemasWhereInput, $first: Int, $after: String) {
    searchSchemas(where: $where, first: $first, after: $after) {
      pageInfo {
        ${pageInfoFields}
      }
      totalCount
      schemas {
        ${searchSchemaFields}
      }
    }
  }
`

export const getAllAttachmentsQuery = gql`
  query getAllAttachments {
    workspaceAttachments(includeLinks: true) {
      reference
      attachmentUrl
      text
      sanitizedText
      schema {
        id
        isPrivate
      }
    }
  }
`

export const suggestedSchemasQuery = gql`
  query suggestedSchemas($where: SuggestedSchemasWhereInput!) {
    suggestedSchemas(where: $where) {
      ${searchSchemaFields}
    }
  }
`

export interface ApiEvent {
  id: string
  name: string
}

const eventFields = `
  id
  name
`

export interface ApiAllEventResponse {
  allEvents: {
    events: ApiEvent[]
  }
}

export const allEventQuery = gql`
  query allEvents($where: EventWhereInput, $orderBy: EventOrderBy) {
    allEvents(where: $where, orderBy: $orderBy) {
      events {
        ${eventFields}
      }
    }
  }
`

export interface ApiWebhook {
  id: number
  title: string
  active: boolean
  events: ApiEvent[]
  url: string
  createdAt: string
  updatedAt: string
}

const webhookFields = `
  id
  title
  active
  events {
    ${eventFields}
  }
  url
  createdAt
  updatedAt
`

export interface ApiAllWebhooksResponse {
  allWebhooks: {
    webhooks: ApiWebhook[]
  }
}

export const allWebhooksQuery = gql`
  query allWebhooks($where: WebhookWhereInput, $orderBy: WebhookOrderBy) {
    allWebhooks(where: $where, orderBy: $orderBy) {
      webhooks {
        ${webhookFields}
      }
    }
  }
`

export interface ApiCreateWebhookResponse {
  createWebhook: {
    webhook: ApiWebhook
  }
}

export const createWebhookMutation = gql`
  mutation createWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhook {
        ${webhookFields}
      }
    }
  }  
`

export interface ApiUpdateWebhookResponse {
  updateWebhook: {
    webhook: ApiWebhook
  }
}

export const updateWebhookMutation = gql`
  mutation updateWebhook($input: UpdateWebhookInput!) {
    updateWebhook(input: $input) {
      webhook {
        ${webhookFields}
      }
    }
  }
`

export interface ApiDeleteWebhookResponse {
  deleteWebhook: {
    success: boolean
  }
}

export const deleteWebhookMutation = gql`
  mutation deleteWebhook($input: DeleteWebhookInput!) {
    deleteWebhook(input: $input) {
      success
    }
  }
`

export const amplitudeLogFields = `
  eventType
  userId
  documentId
  storage
  searchQuery
  documentName
  email
  workspaceId
  workspaceName
  schemaId
`
