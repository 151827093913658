import store from 'src/store'
import { History } from 'history'

export default (history: History) => async (
  _e: any,
  data: { schemaId: string; nodeReference: string }
) => {
  if (!!store.editorStore && store.editorStore.schemaId === data.schemaId) {
    store.editorStore.deleteNode(data.nodeReference, true)
  } else {
    store.update('nodesToDelete', [...store.nodesToDelete, data])
    if (store.editorStore?.schemaId !== data.schemaId) {
      history.push('/' + data.schemaId)
    }
  }
}
