import { History } from 'history'
import store from 'src/store'
import { ISchema } from 'src/store/models/schema'

export default async (history: History) => {
  let toSchema: ISchema | undefined =
    store.binder.teamSpace[0] || store.binder.privateSpace[0]
  if (toSchema) {
    history.push(`/${toSchema.id}`)
  } else {
    const newSchema = await store.createSchema()
    store.binder.initialize()
    if (newSchema) {
      history.push(`/${newSchema.id}`)
    }
  }
}
