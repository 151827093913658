export default {
  blue_fileTag: '#3dd1fd',
  featherBlue: '#59ACFF',
  lightBlue: '#4F9FFF',
  strawBlue: '#379BFF',
  blueTextLight: '#3C9BE1',
  nodeHighlightBg: '#2daadb4d',
  blueTextDark: '#3596DA',
  blue: '#1A8DDC',
  green: '#00D19A',
  greenMedium: '#00C67E',
  greenDark: '#00B16A',
  fileTag: '#fd7f62',
  red: '#FF6563',
  white: '#fff',
  sidebar: '#F8F7FA',
  separator: '#E6E2E2',
  lightGrey: '#C8C8C8',
  selectedForDrag: '#949b9f',
  dateGrey: '#828282',
  grey: '#636A6E',
  topBarGrey: '#4F4F4F',
  darkGrey: '#444',
  googleDocs_fileTag: '#128AF6',
  googleSheets_fileTag: '#14A764',
  googleSlides_fileTag: '#FFBA11',
  black: '#000',
  pngTag: '#EC5970',
  transparentOrange: '#F37F78',
  textGrey: '#616161',
  inputGrey: '#8c89892b',
  introGrey: '#989898',
  introImageText: '#8A8A8A',
  nodeLevel: ['#fd7f62', '#80d8e2', '#bae962', '#fdc83f'],
  tutorialBackground: '#FBFBFB',
  tutorialBackgroundStroke: '#D9D9D9'
}
