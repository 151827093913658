import { isElectron } from 'src/helpers/electron'
import {
  googleOAuth2StorageKey,
  googleOAuth2Client
} from './../../config/index'
import { IGoogleDriveFile } from 'src/store/models/googleDrive'
import { getGoogleDriveFiles } from './../../api/googleDrive'
import { cast, flow, types, destroy, resolveIdentifier } from 'mobx-state-tree'
import API from 'src/api'
import {
  IEditorStore,
  Node
} from 'src/pages/SchemaPage/components/Editor/editorStore'
import node from './node'

import store from '../'
import Schema, { ISchemaSnapshot } from './schema'
import { COLLAPSED_PAGES_STORAGE_KEY } from 'src/config'
import GoogleDriveFile, { IGoogleDriveFileSnapshot } from './googleDrive'
import { ApiSharedSchema } from 'src/api/sharedSchema'

const Binder = types
  .model('Binder', {
    teamSpace: types.array(Schema),
    privateSpace: types.array(Schema),
    googleDrive: types.array(GoogleDriveFile),
    archived: types.optional(
      types.model({
        team: types.array(Schema),
        private: types.array(Schema)
      }),
      {
        team: [],
        private: []
      }
    ),
    collapsedPages: types.array(types.string),
    // for shareable link schema
    sharedSubpages: types.maybe(types.array(node)),
    sharedSchemas: types.array(Schema),
    isOpen: true
  })
  .views(self => ({
    get folderChildren() {
      return self.googleDrive.filter(i => i.isFolder)
    },
    get fileChildren() {
      return self.googleDrive.filter(i => !i.isFolder)
    }
  }))
  .volatile(self => ({
    googleDriveInitialized: false
  }))
  .actions(self => ({
    initialize: flow<typeof self, [boolean?]>(function* intializeBinder(
      ignoreElectronInit = false
    ) {
      const schemas: ISchemaSnapshot[] = yield API.schema.getSchemas(
        {
          archivedAt: { eq: null }
        },
        'createdAt_DESC'
      )
      const privateSpace: ISchemaSnapshot[] = []
      const teamSpace: ISchemaSnapshot[] = []
      schemas.forEach(s => {
        if (s.isPrivate) {
          privateSpace.push(s)
        } else {
          teamSpace.push(s)
        }
      })
      self.privateSpace = cast(privateSpace)
      self.teamSpace = cast(teamSpace)
      const sharedSchemas: ApiSharedSchema[] = yield API.sharedSchema.allSharedSchemasToUser()
      const sharedSpace = sharedSchemas
        .filter(s => !s.schema.archivedAt)
        .map(s => ({
          ...s.schema,
          readonly: s.status === 'READ'
        }))
      self.sharedSchemas = cast(sharedSpace)
      if (isElectron && !ignoreElectronInit) {
        const ipc = (window as any).require('electron').ipcRenderer
        ipc.send('initialize', { privateSpace, teamSpace, sharedSpace })
      }
      try {
        const value = localStorage.getItem(COLLAPSED_PAGES_STORAGE_KEY)
        if (value) {
          self.collapsedPages = JSON.parse(value)
        }
      } catch (e) {
        localStorage.setItem(COLLAPSED_PAGES_STORAGE_KEY, '[]')
      }
      return self
    }),
    // TODO: might not need this
    initGoogleDrive: flow<void, []>(function* initGoogleDrive() {
      try {
        if (1 === 1) return
        const rootFolders: IGoogleDriveFileSnapshot[] = yield getGoogleDriveFiles(
          'root'
        )
        self.googleDrive = cast(rootFolders)

        const getChildren = async (parent: IGoogleDriveFile) => {
          if (!parent.isFolder) return
          const children = await getGoogleDriveFiles(parent.id)
          if (!children || !children.length) return

          parent.update('children', children as any)
          for (const child of parent.children) {
            await getChildren(child)
          }
        }

        for (const folder of self.googleDrive) {
          yield getChildren(folder)
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.googleDriveInitialized = true
      }
    }),
    logoutGoogleDrive: (): void => {
      const googleToken = localStorage.getItem(googleOAuth2StorageKey)
      if (googleToken) {
        googleOAuth2Client.revokeToken(googleToken)
      }
      self.googleDrive.clear()
      localStorage.removeItem(googleOAuth2StorageKey)
      store.update('currentGDriveId', null)
      store.update('isSignedInGoogle', false)
    },
    update: <T extends keyof typeof self>(
      field: T,
      value: typeof self[T]
    ): void => {
      self[field] = value
    },
    getArchived: flow<void, []>(function* getArchived() {
      const schemas: ISchemaSnapshot[] = yield API.schema.getSchemas(
        {
          archivedAt: { not: null }
        },
        'createdAt_DESC'
      )
      const privateSpace: ISchemaSnapshot[] = []
      const teamSpace: ISchemaSnapshot[] = []
      schemas.forEach(s => {
        if (s.isPrivate) {
          privateSpace.push(s)
        } else {
          teamSpace.push(s)
        }
      })
      self.archived.private = cast(privateSpace)
      self.archived.team = cast(teamSpace)
    }),
    toggleBinder: () => (self.isOpen = !self.isOpen),
    toggleCollapse: (id: string): void => {
      if (self.collapsedPages.includes(id)) {
        self.collapsedPages = cast(self.collapsedPages.filter(i => i !== id))
      } else {
        self.collapsedPages.push(id)
      }
      localStorage.setItem(
        COLLAPSED_PAGES_STORAGE_KEY,
        JSON.stringify(self.collapsedPages)
      )
    },
    expandBinderItem: (id: string) => {
      if (!self.collapsedPages.includes(id)) self.collapsedPages.push(id)
      localStorage.setItem(
        COLLAPSED_PAGES_STORAGE_KEY,
        JSON.stringify(self.collapsedPages)
      )
    },
    findSchemaById: (id: string) =>
      self.privateSpace.find(s => s.id === id) ||
      self.teamSpace.find(s => s.id === id) ||
      self.sharedSchemas.find(s => s.id === id),
    destroy
  }))
  .actions(self => ({
    highlightBinderItem: (id: string, store: IEditorStore): void => {
      let expandFather = (nodeId: string) => {
        let node = resolveIdentifier(Node, store, nodeId)!
        if (!node) return
        if (node.type === 'SUBPAGE') {
          self.expandBinderItem(node.id)
        }
        expandFather(node.fatherId)
      }
      expandFather(id)
      self.expandBinderItem(store.schemaId)
    }
  }))
export default Binder
