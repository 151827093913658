import { Auth } from 'aws-amplify'
import { serverHost } from 'src/config'
import { EventType, sendAmplitudeData } from 'src/helpers/analytics'

import { ApiCognitoUserPool } from './gql'

export const signUp = async (
  username: string,
  password: string,
  workspaceId: string
) => {
  const data = await Auth.signUp({
    username,
    password,
    attributes: {
      'custom:workspace_id': workspaceId
    }
  })
  sendAmplitudeData(EventType.UserPasswordInput)
  return data
}

export const confirmSignUp = async (
  userSignUp: {
    username: string
    password: string
  },
  code: string
) => {
  try {
    const data = await Auth.confirmSignUp(userSignUp.username, code)
    if (data === 'SUCCESS') {
      sendAmplitudeData(EventType.Singup)
      sendAmplitudeData(EventType.VerificationFinished)
      return await signIn(userSignUp.username, userSignUp.password)
    }
  } catch (err) {
    console.log('ERROR: ConfirmSignUp: ', err)
  }
}

export const resendSignUp = async (username: string) => {
  try {
    sendAmplitudeData(EventType.Singup)
    await Auth.resendSignUp(username)
  } catch (err) {
    console.log('ERROR: ResendSignUp: ', err)
    throw err
  }
}

export const signIn = async (username: string, password: string) => {
  try {
    const res = await Auth.signIn({
      username,
      password
    })
    return res
  } catch (err) {
    console.log('ERROR: SignIn: ', err)
    throw err
  }
}

export const signOut = async () => {
  try {
    sendAmplitudeData(EventType.Signout)
    await Auth.signOut()
  } catch (err) {
    console.log('ERROR: SignOut: ', err)
  }
}

export const forgotPassword = async (username: string) => {
  try {
    const data = await Auth.forgotPassword(username)
    return data
  } catch (err) {
    console.log('ERROR: ForgotPassword: ', err)
    throw err
  }
}

export const forgotPasswordSubmit = async (
  username: string,
  code: string,
  newPassword: string
) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
  } catch (err) {
    console.log('ERROR: ForgotPasswordSubmit: ', err)
    throw err
  }
}

export const getUserPoolsByUserEmail = async (email: string) => {
  try {
    const res = await fetch(`${serverHost}/getUserPoolsByUserEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    return (await res.json()).userPools as ApiCognitoUserPool[]
  } catch (e) {
    console.log('ERROR: getUserPoolsByUserEmail: ', e)
    return
  }
}
