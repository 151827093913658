import Amplify from 'aws-amplify'
import { google } from 'googleapis'
import * as localforage from 'localforage'
import { ApiCognitoUserPool } from 'src/api/gql'

export const storage = localforage.createInstance({
  name: 'schema-storage'
})

export let amplifyConfiguration: any

export const configureAmplify = async (
  cognito: Pick<ApiCognitoUserPool, 'id' | 'appClientId'>
) => {
  amplifyConfiguration = await Amplify.configure({
    Auth: {
      // TODO make this env
      identityPoolId: process.env.React_APP_COGNITO_IDENTITY_POOL, //REQUIRED - Amazon Cognito Identity Pool ID
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: cognito.id,
      userPoolWebClientId: cognito.appClientId
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_S3_BUCKET, //REQUIRED -  Amazon S3 bucket name
        region: process.env.REACT_APP_AWS_REGION //OPTIONAL -  Amazon service region
      }
    }
  })
}

export const serverHost = process.env.REACT_APP_SERVER_HOST

export const apiLocation = '/graphql/'

export const downloadLink = (nodeId: string) =>
  `${serverHost}/download-file/${nodeId}`

export const passwordMinimumLength = 8
export const verificationCodeLength = 6

export const isDevelopment = process.env.NODE_ENV === 'development'
// TODO: add all probable cdn addresses to cover all Files uploaded correctly and render them as FiletTags.
// export const CDNAddress = 'https://schema-dev1.s3.eu-west-1.amazonaws.com';
export const CDNAddress = process.env.REACT_APP_CDN_ADDRESS

export const slack = {
  clientId: process.env.REACT_APP_SLACK_CLIENT_ID as string,
  clientSecret: process.env.REACT_APP_SLACK_CLIENT_SECRET as string
}

export const S3Bucket = process.env.REACT_APP_S3_BUCKET
export const clientHost = process.env.REACT_APP_CLIENT_HOST!
export const s3Prefix = `https://s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${S3Bucket}/`

export const googleApi = {
  clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID as string,
  clientSecret: process.env.REACT_APP_GOOGLE_API_CLIENT_SECRET as string
}

export const stripeConfig = {
  productPriceId: process.env.REACT_APP_STRIPE_PRODUCT_PRICE_ID as string,
  publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
}

export const googleOAuth2Client = new google.auth.OAuth2(
  googleApi.clientId,
  googleApi.clientSecret,
  clientHost + 'auth/google'
)

const googleOAuthScopes = [
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/documents'
]

export const googleOAuthUrl = googleOAuth2Client.generateAuthUrl({
  // 'online' (default) or 'offline' (gets refresh_token)
  access_type: 'offline',
  prompt: 'consent',

  // If you only need one scope you can pass it as a string
  scope: googleOAuthScopes
})

console.log('googleOAuthUrl', googleOAuthUrl)

google.options({
  auth: googleOAuth2Client
})

export const googleOAuth2StorageKey = 'GoogleOAuth2Tokens'

export const googleDriveFolderStorageKey = 'GoogleDriveFolder'

export const personalUserPool = {
  id: process.env.REACT_APP_COGNITO_PERSONAL_USERPOOL_ID as string,
  clientId: process.env.REACT_APP_COGNITO_PERSONAL_USERPOOL_CLIENT_ID as string
}

export const LAST_OPEN_SCHEMA_ID_STORAGE_KEY = 'LastOpenSchemaId'

export const COLLAPSED_PAGES_STORAGE_KEY = 'CollapsedPages'

export const DEFAULT_ICON = ':file_folder:'

export const ID_PLACEHOLDER = 'PLACEHOLDER'
