import { Instance, SnapshotIn, types } from 'mobx-state-tree'

const User = types
  .model('User', {
    id: types.identifier,
    fullName: '',
    email: types.string,
    role: types.string,
    tutorialSeen: false,
    findOutAboutSchemaFrom: types.maybeNull(types.string),
    describeWhatYouDo: types.maybeNull(types.string),
    schemaUsage: types.maybeNull(types.string),
    googleDriveFolderId: types.maybeNull(types.string),
    showThumbnail: true,
    createdAt: types.string,
    updatedAt: types.string
  })
  .actions(self => ({
    update: (partial: Partial<typeof self>): void =>
      Object.keys(partial).forEach(k => (self[k] = partial[k]))
  }))

export default User
export interface IUser extends Instance<typeof User> {}
export interface IUserSnapshot extends SnapshotIn<typeof User> {}
