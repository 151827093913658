import 'src/pages/SchemaPage/components/Editor/styles/simple.css'

import { Auth } from 'aws-amplify'
import createHistory from 'history/createBrowserHistory'
import * as jwtDecode from 'jwt-decode'
import { toJS } from 'mobx'
import { observer, Provider } from 'mobx-react'
import * as React from 'react'
import { isMobile } from 'react-device-detect'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { Helmet } from 'react-helmet'
import { MemoryRouter, Route, Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { createGlobalStyle } from 'styled-components'

import loadable from '@loadable/component'
import { Color } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme } from '@material-ui/core/styles'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

import { ApiWorkspace } from './api/gql'
import LoginController, {
  googleOauthTokenHandler
} from './components/controllers/LoginController'
// import Onboarding from './components/Onboarding';
import SchemaSnackbar from './components/SchemaSnackbar'
import { clientHost, googleOAuth2StorageKey } from './config'
import { getApolloClient, refreshToken } from './config/apolloClient'
import colors from './config/colors'
import constants from './config/constants'
import onArchiveSchema from './electron/eventListeners/onArchiveSchema'
import onDeleteNode from './electron/eventListeners/onDeleteNode'
import onMentionListUpdate from './electron/eventListeners/onMentionListUpdate'
import onUpdateNode from './electron/eventListeners/onUpdateNode'
import onUpdateSchema from './electron/eventListeners/onUpdateSchema'
import { isElectron } from './helpers/electron'
import store from './store'
import ui from './store/ui'

const GoogleAuth = loadable(() => import('./pages/auth/GoogleAuth'))
const Invitation = loadable(() => import('./pages/auth/Invitation'))
const SignIn = loadable(() => import('./pages/auth/SignIn'))
const SignUp = loadable(() => import('./pages/auth/SignUp'))
const DevPortal = loadable(() => import('./pages/DevPortal'))
const Landing = loadable(() => import('./pages/Landing'))
const LandingMobile = loadable(() => import('./pages/Landing/mobile'))
const Privacy = loadable(() => import('./pages/Privacy'))
const SchemaPage = loadable(() => import('./pages/SchemaPage'))
const Terms = loadable(() => import('./pages/Terms'))
// const TestEditor = loadable(() => import('./pages/TestEditor'));
const PaySuccess = loadable(() => import('./pages/PaySuccess'))
const ElectronLogs = loadable(() => import('./pages/DevPortal/ElectronLogs'))

const CustomRouter: any = isElectron ? MemoryRouter : Router

export const history = createHistory()

let isElectronSetUp = false

interface IProps {}

interface IState {
  workspace: ApiWorkspace | undefined
}

// white background as default background in mobile devices.
const GlobalStyle = createGlobalStyle`
  @media only screen and (max-width: 600px) {
    html {
      background-color: white;
    }
  }
`

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Avenir"', '"Nunito Sans"', 'Lato', 'sans-serif'].join(',')
  },
  palette: {
    text: {
      primary: '#363636',
      secondary: '#4D4D4D'
    },
    primary: {
      main: colors.blue,
      light: colors.featherBlue,
      contrastText: '#fff'
    },
    secondary: {
      main: colors.green,
      dark: colors.greenDark
    },
    error: {
      main: colors.red
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: colors.sidebar,
      300: colors.separator,
      A100: colors.lightGrey,
      400: '#bdbdbd',
      A200: '#aaaaaa',
      500: colors.selectedForDrag,
      600: '#757575',
      A700: colors.grey,
      700: '#616161',
      800: colors.darkGrey,
      A400: '#303030',
      900: '#212121'
    } as Partial<Color>
  },
  spacing: factor => factor * constants.spacingUnit
})

const NotFound = () => (
  <div>
    <h2>404 Not Found</h2>
    <p className="App-intro">The page you were looking for does not exist.</p>
  </div>
)

export interface SchemaRouteParams {
  id: string
  rootNode?: string
}

class App extends React.Component<IProps, IState> {
  private intervalId: NodeJS.Timeout

  constructor(props: IProps) {
    super(props)
  }

  async componentDidMount() {
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const token = cognitoUser.signInUserSession.idToken.jwtToken
        const tokenPayload = jwtDecode<{ exp: number }>(token)
        if (tokenPayload.exp * 1000 < Date.now()) {
          const client = await getApolloClient()
          client.stop()
          refreshToken()
        }
      }
    })
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalId)
  }

  public render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Helmet>
          {/*Hotjar Tracking Code for https://schema.team */}
          <script>
            {clientHost === 'https://schema.team/' &&
              `
              (function(h, o, t, j, a, r) {
                h.hj =
                  h.hj ||
                  function() {
                    (h.hj.q = h.hj.q || []).push(arguments);
                  };
                h._hjSettings = { hjid: 1564803, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
              `}
          </script>
          <script type="text/javascript">
            {`
            var crispId = '3a33fbe9-d68b-47b4-bb1d-aafc39c27f7d'
            if (window.CRISP_WEBSITE_ID !== crispId) {
              window.$crisp = [];
              $crisp.push(['safe', true]);
              window.CRISP_WEBSITE_ID = crispId;
              (function () {
                d = document;
                s = d.createElement('script');
                s.src = 'https://client.crisp.chat/l.js';
                s.async = 1;
                d.getElementsByTagName('head')[0].appendChild(s);
              })();
            }
            `}
          </script>
        </Helmet>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyle />
          <CustomRouter history={history}>
            <Switch>
              <Route
                exact={true}
                path={'/#*'}
                render={() => (!isMobile ? <Landing /> : <LandingMobile />)}
              />
              <Route
                exact={true}
                path={'/early-access'}
                render={() => {
                  return !isMobile ? (
                    <Landing variantB />
                  ) : (
                    <LandingMobile variantB />
                  )
                }}
              />
              <Route
                exact={true}
                path={'/privacy'}
                render={() => <Privacy />}
              />
              <Route exact={true} path={'/terms'} render={() => <Terms />} />
              <Route
                exact={true}
                path={'/auth/google'}
                render={() => <GoogleAuth />}
              />
              <Route
                exact={true}
                path="/shared-schema/:id/:rootNode?"
                render={props => {
                  let token = props.location.search.split('?token=')[1]
                  window['sharedSchemaToken'] = token
                  return <SchemaPage logout={() => {}} isShareableLink />
                }}
              />
              <Route exact path="/success" render={() => <PaySuccess />} />
              <Route
                exact={true}
                path="/404.html"
                render={() => <NotFound />}
              />
              <LoginController>
                {({ logout, history, checkLogin }) => {
                  if (!isElectronSetUp && isElectron && store.isInitialized) {
                    const ipc = (window as any).require('electron').ipcRenderer
                    ipc.on('console', (event: any, message: any) => {
                      console.log(message)
                    })
                    ipc.on('deleteNode', onDeleteNode(history))
                    ipc.on('archiveSchema', onArchiveSchema(history))
                    ipc.on('updateNode', onUpdateNode)
                    ipc.on('updateSchema', onUpdateSchema)
                    ipc.on('mentionListUpdate', onMentionListUpdate)
                    ipc.on('initializeBinder', () =>
                      store.binder.initialize(true)
                    )
                    ipc.on(
                      're-initialize',
                      async (e: any, data: { silent?: boolean }) => {
                        const binder = toJS(await store.binder.initialize(true))
                        ipc.send('initialize', {
                          privateSpace: binder.privateSpace,
                          teamSpace: binder.teamSpace,
                          sharedSpace: binder.sharedSchemas,
                          silent: data?.silent
                        })
                      }
                    )
                    ipc.on(
                      'refresh-token',
                      (e: any, data: { reInit: boolean }) =>
                        refreshToken(data?.reInit)
                    )
                    ipc.on('google-oauth-token', (e: any, token: any) => {
                      localStorage.setItem(
                        googleOAuth2StorageKey,
                        JSON.stringify(token)
                      )
                      googleOauthTokenHandler(token)
                    })
                    ipc.on('addDownloadingFile', (e: any, ref: string) =>
                      store.addDownloadingFile(ref)
                    )
                    ipc.on('removeDownloadingFile', (e: any, ref: string) =>
                      store.removeDownloadingFile(ref)
                    )
                    isElectronSetUp = true
                  }
                  return (
                    <Provider>
                      <>
                        <Switch>
                          <Route
                            exact={true}
                            path={'/'}
                            render={() =>
                              !isMobile ? <Landing /> : <LandingMobile />
                            }
                          />
                          <Route
                            exact
                            path="/dev-portal/electron-logs"
                            render={() => <ElectronLogs />}
                          />
                          <Route
                            exact={true}
                            path="/dev-portal"
                            render={() => <DevPortal store={store} />}
                          />
                          <Route
                            exact={true}
                            path="/signup"
                            render={() => <SignUp checkLogin={checkLogin} />}
                          />
                          <Route
                            exact={true}
                            path="/signin"
                            render={() => <SignIn checkLogin={checkLogin} />}
                          />
                          <Route
                            exact={true}
                            path="/auth"
                            render={() => (
                              <SignIn isIntegration checkLogin={checkLogin} />
                            )}
                          />
                          <Route
                            exact={true}
                            path="/invitation/:token"
                            render={() => <Invitation />}
                          />
                          <Route
                            exact={true}
                            path="/:id/:rootNode?"
                            render={() => <SchemaPage logout={logout} />}
                          />
                          <Route
                            exact={true}
                            path="/shared/:id"
                            render={() => (
                              <SchemaPage isSharedSchema logout={logout} />
                            )}
                          />
                          {/* {isDevelopment && (
                            <Route
                              exact={true}
                              path="/test-editor/:test?"
                              render={() => <TestEditor />}
                            />
                          )} */}
                          <Route render={() => <NotFound />} />
                        </Switch>
                        <SchemaSnackbar
                          open={ui.snackbar.isOpen}
                          message={ui.snackbar.message}
                          onClose={ui.snackbar.close}
                          variant={ui.snackbar.variant}
                        />
                        {/* TODO: remove */}
                        {/* <Onboarding store={store} /> */}
                      </>
                    </Provider>
                  )
                }}
              </LoginController>
            </Switch>
          </CustomRouter>
          <ToastContainer />
        </MuiThemeProvider>
      </DndProvider>
    )
  }
}

export default observer(App)
