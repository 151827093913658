import { resolveIdentifier } from 'mobx-state-tree'
import store from 'src/store'

import { Node } from '../../pages/SchemaPage/components/Editor/editorStore'

export default (
  _e: any,
  node: { id: string; schemaId: string; text?: string; attachmentUrl?: string }
) => {
  if (store.editorStore && node.schemaId === store.editorStore.schemaId) {
    const targetNode = resolveIdentifier(Node, store.editorStore, node.id)
    if (targetNode) {
      if (node.text) {
        targetNode.update('text', node.text)
      }
      if (node.attachmentUrl) {
        targetNode.update('attachmentUrl', node.attachmentUrl)
      }
    }
  }
}
