import { isElectron } from './../helpers/electron'
import { Storage } from 'aws-amplify'
import { getApolloClient } from 'src/config/apolloClient'
import { EventType, sendAmplitudeData } from 'src/helpers/analytics'

import {
  createUrlAttachmentMutation,
  deleteAttachmentMutation,
  duplicateAttachmentMutation
} from './gql'
import { s3Prefix } from 'src/config'
import { gql } from 'apollo-boost'

export const uploadFile = async (
  file: File,
  schemaId: string,
  nodeId: string,
  progressCallback?: (e: ProgressEvent) => void
): Promise<string> => {
  const res = await Storage.put(`/${nodeId}/${file.name}`, file, {
    contentType: file.type,
    metadata: {
      mimetype: file.type,
      schemaId,
      nodeId
    },
    customPrefix: {
      public: schemaId
    },
    progressCallback
  })

  const url = `${s3Prefix}${schemaId}${res['key']}`
  await attachUrl(url, schemaId, nodeId)
  sendAmplitudeData(EventType.FileUpload, { schema_id: schemaId })
  return url
}

export const duplicateAttachment = async (
  nodeId: string,
  targetNodeId: string
): Promise<string> => {
  const client = await getApolloClient()

  const res = await client.mutate({
    mutation: duplicateAttachmentMutation,
    variables: {
      input: { nodeId, targetNodeId }
    }
  })

  sendAmplitudeData(EventType.FileDuplication, { node_id: nodeId })
  return res.data!.duplicateAttachment.url
}

export const attachUrl = async (
  url: string,
  schemaId: string,
  nodeId: string
): Promise<string> => {
  const client = await getApolloClient()
  const res = await client.mutate({
    mutation: createUrlAttachmentMutation,
    variables: {
      input: { url, schemaId, nodeId }
    }
  })
  sendAmplitudeData(EventType.AttachUrl, { schema_id: schemaId })
  return res.data!.createUrlAttachment.attachment.url
}

export const deleteAttachment = async (nodeId: string): Promise<void> => {
  const client = await getApolloClient()
  await client.mutate({
    mutation: deleteAttachmentMutation,
    variables: {
      input: {
        nodeId
      }
    }
  })
  sendAmplitudeData(EventType.DeleteFile)
}

export const createAttachmentSubscription = async () => {
  if (!isElectron) return

  const client = await getApolloClient()
  const subscription = client.subscribe({
    query: gql`
      subscription createAttachmentSubscription {
        CreateAttachmentPayload {
          payload {
            attachment {
              filename
              size
              url
              fileModifiedTime
            }
            workspaceId
            schemaId
            nodeRef
          }
        }
      }
    `
  })
  subscription.subscribe(response => {
    const ipc = (window as any).require('electron').ipcRenderer
    ipc.send('create-attachment-subscription', response)
  })
}

export interface AttachmentMetadata {
  lastModified?: string
  size?: number
  mimetype?: string
}

export const getAttachmentMetadata = async (url: string) => {
  const client = await getApolloClient()
  const res = await client.query<{ getAttachmentMetadata: AttachmentMetadata }>(
    {
      query: gql`
        query getAttachmentMetadata($url: String!) {
          getAttachmentMetadata(url: $url) {
            lastModified
            size
            mimetype
          }
        }
      `,
      variables: { url }
    }
  )
  return res.data.getAttachmentMetadata
}
