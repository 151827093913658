import { Auth } from 'aws-amplify'
import { serverHost } from 'src/config'
import { getApolloClient } from 'src/config/apolloClient'
import { IWorkspaceSnapshot } from 'src/store/models/workspace'

import {
  allWorkspacesQuery,
  ApiWorkspace,
  createWorkspaceMutation,
  getWorkspaceByIdQuery,
  GetWorkspaceResponse,
  updateWorkspaceMutation
} from './gql'

const randomize = require('randomatic')

export const findWorkspaceByName = async (workspaceName: string) => {
  let workspace: ApiWorkspace | undefined
  try {
    const res = await fetch(`${serverHost}/findWorkspace`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ workspaceName })
    })
    workspace = (await res.json()).workspace
  } catch (err) {
    console.log('ERROR: findWorkspaceByName:', err)
  } finally {
    return workspace
  }
}

export const getWorkspaceById = async (
  id: string
): Promise<IWorkspaceSnapshot> => {
  try {
    const client = await getApolloClient()
    const res = await client.query<GetWorkspaceResponse>({
      query: getWorkspaceByIdQuery,
      variables: {
        id
      }
    })
    return res.data!.workspace
  } catch (err) {
    console.log('ERROR: getWorkspaceById: ', err)
    throw err
  }
}

export const allWorkspaces = async (where?: any) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{
      allWorkspaces: { workspace: ApiWorkspace[] }
    }>({
      query: allWorkspacesQuery,
      variables: {
        orderBy: 'createdAt_DESC',
        where
      },
      fetchPolicy: 'network-only'
    })
    return res.data.allWorkspaces.workspace
  } catch (err) {
    console.log('ERROR: allWorkspaces: ', err)
    return []
  }
}

export const createWorkspace = async (workspaceName: string) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: createWorkspaceMutation,
      variables: {
        input: {
          name: workspaceName
        }
      }
    })) as { data: { createWorkspace: { workspace: ApiWorkspace } } }
    return res.data.createWorkspace.workspace
  } catch (err) {
    console.log('ERROR: createWorkspace: ', err)
    return
  }
}

export const updateWorkspace = async (workspace: {
  id: string
  name: string
  emailDigestEnabled?: boolean
  timeZone?: number
}) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: updateWorkspaceMutation,
      variables: { input: { workspace } }
    })) as { data: { updateWorkspace: { workspace: ApiWorkspace } } }
    return res.data.updateWorkspace.workspace
  } catch (err) {
    console.log('ERROR: updateWorkspace: ', err)
    return
  }
}

export const createPersonalWorkspace = async (workspaceName: string) => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  if (!cognitoUser) {
    throw new Error('Cognito User not found.')
  }

  const res = await fetch(`${serverHost}/createPersonalWorkspace`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${cognitoUser.signInUserSession.idToken.jwtToken}`
    },
    body: JSON.stringify({ workspaceName })
  })

  return await res.json()
}

export const getUniqueWorkspaceName = async (
  email: string
): Promise<string> => {
  const randomizedName = email.split('@')[0] + randomize('A0', 5)
  const existingWorkspace = await findWorkspaceByName(randomizedName)
  if (!existingWorkspace) {
    return randomizedName
  } else {
    return await getUniqueWorkspaceName(email)
  }
}
