import { getApolloClient } from 'src/config/apolloClient'
import { gql } from 'apollo-boost'

const mutation = gql`
  mutation createStatistic($input: CreateStatisticInput!) {
    createStatistic(input: $input) {
      success
    }
  }
`

export const createStatistic = async (input: {
  lastSignIn?: Date
  fileUploadSize?: number
  openSchemaId?: string
  openFileId?: string
}) => {
  try {
    const client = await getApolloClient()
    await client.mutate({
      mutation,
      variables: { input }
    })
  } catch (e) {
    console.error('ERROR: createStatistic: ', e)
  }
}
