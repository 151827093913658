import { cast, flow, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { ApiSharedSchema, SharedSchemaStatus } from 'src/api/sharedSchema'

import store from '../'
import {
  allSharedSchemasFromUser,
  updateSharedSchema
} from '../../api/sharedSchema'
import node, { DummyNode } from './node'
import { IUser } from './user'

const Collaborator = types.model('Collaborator', {
  id: types.identifier,
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  focusedElementId: types.maybeNull(types.string),
  caretPosition: types.maybeNull(types.number),
  updatedAt: types.string
})

const Share = types
  .model('Share', {
    id: types.identifier,
    email: types.string,
    status: types.enumeration<SharedSchemaStatus>('SharedSchemaStatus', [
      'READ',
      'DISABLED',
      'READWRITE'
    ]),
    pending: false
  })
  .actions(self => ({
    update: flow<void, [SharedSchemaStatus]>(function* update(status) {
      self.status = status
      yield updateSharedSchema(self.id, status)
    })
  }))

const schemaFactory = (useReference = false) =>
  types
    .model(useReference ? 'Schema' : 'DummySchema', {
      id: useReference ? types.identifier : types.string,
      title: '',
      inline: true,
      archivedAt: types.maybeNull(types.string),
      nodes: types.array(DummyNode),
      subpages: types.array(node),
      isPrivate: types.boolean,
      icon: types.maybeNull(types.string),
      createdAt: types.string,
      updatedAt: types.string,
      updatedByUserId: types.maybeNull(types.string),
      collaborators: types.array(Collaborator),
      readonly: types.maybeNull(types.boolean),
      shares: types.array(Share),
      sharedSchemaId: types.maybeNull(types.string),
      sharedNode: types.maybeNull(
        types.model('SharedNode', {
          id: types.number,
          reference: types.string,
          text: types.maybeNull(types.string)
        })
      )
    })
    .actions(self => ({
      update: <T extends keyof Omit<typeof self, 'nodes'>>(
        field: T,
        value: typeof self[T]
      ): void => {
        self[field] = value
      },
      getShares: flow(function* getShares(): any {
        const allShares: ApiSharedSchema[] = yield allSharedSchemasFromUser(
          self.id,
          store.editorStore?.rootNode?.id
        )
        self.shares = cast(
          allShares.map(s => ({
            id: s.id,
            email: s.recipientEmail || s.toUserEmail || '',
            status: s.status,
            pending: !s.toUserId
          }))
        )
      })
    }))
    .views(self => ({
      get updatedBy(): IUser | undefined {
        if (!store.workspace) {
          return
        }
        return store.workspace.users.find(
          user => user.id === self.updatedByUserId
        )
      },
      get isSharedSchema(): boolean {
        return !!store.binder.sharedSchemas.find(s => s.id === self.id)
      }
    }))

const Schema = schemaFactory(true)
export const DummySchema = schemaFactory()

export default Schema
export interface ISchema extends Instance<typeof Schema> {
  _destroy?: boolean
}
export interface ISchemaSnapshot extends SnapshotIn<typeof Schema> {
  _destroy?: boolean
}

export interface ICollaborator extends Instance<typeof Collaborator> {}
export interface IShare extends Instance<typeof Share> {}
