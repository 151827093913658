import { History } from 'history'
import navigateToSchemaPage from 'src/helpers/navigateToSchemaPage'
import store from 'src/store'

export default (history: History) => async (_e: any, schemaId: string) => {
  const schema = store.binder.findSchemaById(schemaId)

  if (schema) {
    store.binder.destroy(schema)
  }

  if (store.editorStore?.schemaId === schemaId) {
    await navigateToSchemaPage(history)
  }
}
