import { gql } from 'apollo-boost'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'
import { getApolloClient } from 'src/config/apolloClient'
import constants from 'src/config/constants'
import { EventType, sendAmplitudeData } from 'src/helpers/analytics'
import { isElectron, ipc } from 'src/helpers/electron'
import store from 'src/store'
import { INodeSnapshot } from 'src/store/models/node'
import { ISchemaSnapshot } from 'src/store/models/schema'

import {
  allSchemasQuery,
  ApiAllSchemasResult,
  ApiArchiveSchemaResponse,
  ApiCreateSchemaResult,
  ApiGetSchemaByIdResult,
  ApiNode,
  ApiSchema,
  ApiSchemaSearchItem,
  ApiSearchSchemasResponse,
  ApiUnarchiveSchemaResponse,
  ApiUpdateSchemaResult,
  archiveSchemaMutation,
  createSchemaMutation,
  deleteSchemaMutation,
  getAllAttachmentsQuery,
  getSchemaByIdQuery,
  searchSchemasQuery,
  suggestedSchemasQuery,
  unarchiveSchemaMutation,
  updateSchemaMutation,
  updateSchemaSubscription
} from './gql'

const sendUpdateSchemaEvent = debounce(
  () => sendAmplitudeData(EventType.UpdateSchema),
  constants.delays.analyticEventDelay,
  {
    leading: true,
    trailing: false
  }
)
export const getSchemaById = async (
  id: string,
  nodeRef?: string
): Promise<ISchemaSnapshot | undefined> => {
  try {
    let client = await getApolloClient()
    const res = await client.query<ApiGetSchemaByIdResult>({
      fetchPolicy: 'network-only',
      query: getSchemaByIdQuery,
      variables: { id, nodeRef }
    })
    sendAmplitudeData(EventType.OpenSchema, { schema_id: id })
    return res.data.schema as any
  } catch (e) {
    console.error('getSchemaById: ', e)
    toast.error('Unable to load schema')
    return
  }
}

export const getSchemas = async (
  where = {},
  orderBy?: string,
  first?: number
): Promise<ISchemaSnapshot[]> => {
  const client = await getApolloClient()
  const res = await client.query<ApiAllSchemasResult>({
    fetchPolicy: 'no-cache',
    query: allSchemasQuery,
    variables: {
      where,
      orderBy,
      first
    }
  })

  const schemas: ISchemaSnapshot[] = res.data.allSchemas.schema.map(
    fromApiSchema
  )
  return schemas
}

export const fromApiSchema = (schema: ApiSchema): ISchemaSnapshot => ({
  id: schema.id,
  title: schema.title,
  nodes: [],
  icon: schema.icon,
  readonly: schema.readonly,
  sharedSchemaId: schema.sharedSchemaId,
  subpages: schema.subpages ? fromApiSubpages(schema.subpages as any) : [],
  archivedAt: schema.archivedAt,
  isPrivate: schema.isPrivate,
  createdAt: schema.createdAt,
  updatedAt: schema.updatedAt
})

const subpageIsAfter = (a: number[], b: number[]) => {
  for (let i = 0; i < a.length; i++) {
    if (a[i] > b[i]) {
      return true
    } else if (a[i] < b[i]) {
      return false
    } else {
      continue
    }
  }
  return a.length > b.length
}

const fromApiSubpages = (subpages: Array<ApiNode & INodeSnapshot>) => {
  const childrenIds: number[] = []
  subpages.forEach(s => {
    if (s.parentPage && s.parentPage.parentPageReference) {
      const parent = subpages.find(
        p => p.reference === s.parentPage!.parentPageReference
      )
      if (parent) {
        childrenIds.push(s.id)
        if (Array.isArray(parent.subpages)) {
          parent.subpages.push(s)
        } else {
          parent.subpages = [s]
        }
      }
    }
  })

  subpages.forEach(s => {
    if (Array.isArray(s.subpages)) {
      s.subpages.sort(
        (a: ApiNode & INodeSnapshot, b: ApiNode & INodeSnapshot) =>
          subpageIsAfter(a.parentPage!.relativePath, b.parentPage!.relativePath)
            ? 1
            : -1
      )
    }
  })

  return subpages
    .filter(s => !childrenIds.includes(s.id))
    .sort((a: ApiNode & INodeSnapshot, b: ApiNode & INodeSnapshot) =>
      subpageIsAfter(a.parentPage!.relativePath, b.parentPage!.relativePath)
        ? 1
        : -1
    )
}

export const createSchema = async (
  isPrivate = true
): Promise<ISchemaSnapshot> => {
  sendAmplitudeData(EventType.CreateSchema)
  const client = await getApolloClient()
  const res = (await client.mutate({
    mutation: createSchemaMutation,
    variables: {
      input: { title: '', isPrivate: isPrivate }
    }
  })) as { data: ApiCreateSchemaResult }
  return res.data!.createSchema.schema
}

export const updateSchema = async (
  schema: Partial<ISchemaSnapshot>,
  collaborator?: {
    focusedElementId?: string
    caretPosition?: number
    _destroy?: boolean
  },
  token?: string
): Promise<ISchemaSnapshot> => {
  sendUpdateSchemaEvent()
  const client = await getApolloClient()
  const res = await client.mutate<ApiUpdateSchemaResult>({
    mutation: updateSchemaMutation,
    variables: {
      input: { schema, collaborator, token }
    }
  })
  if (
    schema.nodes &&
    schema.nodes.some((node: INodeSnapshot) => !!node.attachmentUrl)
  )
    store.rehydrateMentions()
  return res.data!.updateSchema.schema
}

export const deleteSchema = async (schemaId: string): Promise<void> => {
  const client = await getApolloClient()
  await client.mutate({
    mutation: deleteSchemaMutation,
    variables: {
      input: { id: schemaId }
    }
  })
  sendAmplitudeData(EventType.DeleteSchema, { schema_id: schemaId })
}

export const archiveSchema = async (id: string): Promise<ISchemaSnapshot> => {
  const client = await getApolloClient()
  const res = (await client.mutate({
    mutation: archiveSchemaMutation,
    variables: {
      input: { id }
    }
  })) as { data: ApiArchiveSchemaResponse }
  sendAmplitudeData(EventType.ArchiveSchema, { schema_id: id })
  return res.data.archiveSchema.schema
}

export const unarchiveSchema = async (id: string): Promise<ISchemaSnapshot> => {
  const client = await getApolloClient()
  const res = (await client.mutate({
    mutation: unarchiveSchemaMutation,
    variables: {
      input: { id }
    }
  })) as { data: ApiUnarchiveSchemaResponse }
  sendAmplitudeData(EventType.UnarchiveSchema, { schema_id: id })
  return res.data.unarchiveSchema.schema
}

export const updateSchemaPayload = async (id: string) => {
  const client = await getApolloClient()
  return await client.subscribe({
    query: updateSchemaSubscription,
    variables: { id }
  })
}

export const searchSchemas = async (
  query: string
): Promise<ApiSearchSchemasResponse | undefined> => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{ searchSchemas: ApiSearchSchemasResponse }>(
      {
        query: searchSchemasQuery,
        variables: {
          where: {
            query
          }
        }
      }
    )
    sendAmplitudeData(EventType.Search, { search_query: query })
    return res.data.searchSchemas
  } catch (err) {
    console.log('ERROR: searchSchemas: ', err)
    return
  }
}

export const getAllAttachments = async (): Promise<any> => {
  try {
    const client = await getApolloClient()
    const res = await client.query<any>({
      query: getAllAttachmentsQuery,
      fetchPolicy: 'no-cache'
    })
    return res.data.workspaceAttachments
  } catch (e) {
    console.log('ERROR: not all attachments result: ', e)
    return
  }
}

export const suggestedSchemas = async (query: string, schemaId: string) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{
      suggestedSchemas: ApiSchemaSearchItem[]
    }>({
      query: suggestedSchemasQuery,
      variables: { where: { query, schemaId } }
    })
    return res.data.suggestedSchemas
  } catch (err) {
    console.log('ERROR: suggestedSchemas: ', err)
    return []
  }
}

export const archiveSchemaSubscription = async () => {
  if (!isElectron) return

  const client = await getApolloClient()
  const subscription = client.subscribe({
    query: gql`
      subscription updateSchemaSubscription($id: ID) {
        ArchiveSchemaPayload(id: $id) {
          payload {
            schema {
              id
            }
          }
        }
      }
    `
  })
  subscription.subscribe(res => ipc?.send('archive-schema-subscription', res))
}

export const nodeFields = `
  id
  reference
  type
  text
  fatherReference
  attachmentUrl
  fileSize
  parents {
    reference
    text
    type
    attachmentUrl
  }
`

// TODO: should combine `updateSchemaPayload` and this into one
export const electronUpdateSchemaSubscription = async () => {
  if (!isElectron) return

  const client = await getApolloClient()
  const subscription = client.subscribe({
    query: gql`
    subscription updateSchemaSubscription($id: ID) {
      UpdateSchemaPayload(id: $id) {
        payload {
          schema {
            id
            title
            nodes {
              created {
                ${nodeFields}
              }
              updated {
                ${nodeFields}
              }
              deleted {
                reference
              }
            }
          }
        }
      }
    }
  `
  })
  subscription.subscribe(res => ipc?.send('update-schema-subscription', res))
}
