import { google } from 'googleapis'

export const googleDriveFields =
  'files(id, kind, mimeType, name, iconLink, thumbnailLink, webViewLink)'

export const getGoogleDriveFiles = async (parentId: string) => {
  try {
    const q =
      parentId === 'root'
        ? `('root' in parents or sharedWithMe = true) and trashed=false`
        : `'${parentId}' in parents and trashed=false`
    const res = await google.drive('v3').files.list({
      q,
      fields: googleDriveFields
    })
    return res.data.files
  } catch (e) {
    console.error(e)
    return
  }
}
