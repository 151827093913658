import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import User from './user'

const Workspace = types
  .model('Workspace', {
    id: types.identifier,
    name: types.string,
    users: types.array(User),
    createdAt: types.string,
    updatedAt: types.string
  })
  .actions(self => ({
    update: (field: keyof typeof self, value: any): void =>
      (self[field] = value)
  }))

export default Workspace
export interface IWorkspace extends Instance<typeof Workspace> {}
export interface IWorkspaceSnapshot extends SnapshotIn<typeof Workspace> {}
