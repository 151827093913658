import { NewNodeInput } from './../helpers/index'
import {
  applySnapshot,
  cast,
  destroy,
  detach,
  getParent,
  getParentOfType,
  getSnapshot,
  getType,
  IAnyModelType,
  Instance,
  resolveIdentifier,
  SnapshotIn,
  types,
  walk,
  onPatch
} from 'mobx-state-tree'
import { NodeTypeEnum } from 'src/api/gql'
import { deleteAttachment } from 'src/api/node'
import { s3Prefix } from 'src/config'
import store from 'src/store'
import Schema from 'src/store/models/schema'
import node from 'src/store/models/node'

import * as SelectionJs from '@simonwep/selection-js'

import { createStatistic } from '../../../../../api/statistic'
import { fromEditorToBinderSchema } from '../../Binder/helpers'
import { addNewChildren, getSiblingNode, addNewChildrenV3 } from '../helpers'
import { highlightEl, unhighlightEl } from '../helpers/documents'
import { pick } from 'lodash'

let deletedNodeIds: string[] = []

export const addDeleteNodeId = (id: string) => {
  deletedNodeIds.push(id)
  setTimeout(
    () => (deletedNodeIds = deletedNodeIds.filter(i => i !== id)),
    1000 * 30
  )
}
export const getDeletedNodeIds = () => deletedNodeIds

export interface NewNode
  extends Partial<
    Pick<
      INode,
      'icon' | 'attachmentUrl' | 'language' | 'checked' | 'type' | 'text'
    >
  > {
  children?: NewNode[]
}

export const preSnapshot = (nodes: INode[]): void => {
  nodes.forEach(n => {
    n.bulkUpdate({ originalText: n.text, index: n.currentIndex })
    if (n.children.length) {
      preSnapshot(n.children)
    }
  })
}

// TODO: https://github.com/mobxjs/mobx-state-tree/issues/417
export const Node = types
  .model('NodeItem', {
    id: types.identifier,
    index: types.number,
    type: types.enumeration<NodeTypeEnum>('NodeType', [
      'BULLET_POINT',
      'PARAGRAPH',
      'H1',
      'H2',
      'H3',
      'CHECKBOX',
      'CODE_SNIPPET',
      'SUBPAGE'
    ]),
    originalText: types.optional(types.string, ''),
    language: types.maybeNull(types.string),
    checked: types.optional(types.boolean, false),
    fatherId: types.string,
    icon: types.maybeNull(types.string),
    attachmentUrl: types.maybeNull(types.string),
    fileSize: types.maybeNull(types.number),
    collapsed: types.boolean,
    children: types.array(types.late((): IAnyModelType => Node)),
    externalId: types.maybe(types.number),
    editedAt: types.maybeNull(types.number)
  })
  .volatile(self => ({
    fileUploadProgress: undefined as number | undefined,
    currentIndex: self.index,
    text: self.originalText,
    isNew: false
  }))
  .views(self => ({
    get lastChild(): INode {
      return self.children[self.children.length - 1] as INode
    },
    get isEmpty(): boolean {
      return (
        !self.text &&
        !self.attachmentUrl &&
        self.fileUploadProgress === undefined
      )
    },
    get isDownloading(): boolean {
      return store.downloadingFiles.includes(self.id)
    }
  }))
  .actions(self => ({
    update: <T extends keyof typeof self>(
      field: T,
      value: typeof self[T]
    ): void => {
      self[field] = value
      if (
        field === 'attachmentUrl' &&
        typeof value === 'string' &&
        !value.startsWith(s3Prefix)
      ) {
        createStatistic({
          fileUploadSize: 0
        })
      }
    },
    bulkUpdate: (value: Partial<typeof self>): void => {
      Object.keys(value).forEach(k => (self[k] = value[k]))
      if (value.attachmentUrl && !value.attachmentUrl.startsWith(s3Prefix)) {
        createStatistic({
          fileUploadSize: 0
        })
      }
    },
    replaceChildren: (children: INode[]): INode[] =>
      self.children.replace(children),
    splice: (index: number, item: INode): INode[] => {
      store.editorStore!.onEditorChange('updatedNodes', {
        ...getSnapshot(self),
        originalText: self.text,
        index: self.currentIndex
      })
      return self.children.splice(index, 0, item)
    },
    remove: (index: number): INode[] => self.children.splice(index, 1),
    pushChild: (child: INode): void => {
      self.children.push(child)
      self.editedAt = Date.now()
      store.editorStore!.onEditorChange('updatedNodes', {
        ...getSnapshot(self),
        originalText: self.text,
        index: self.currentIndex
      })
    }
  }))
  .actions(self => {
    // store each node's forceUpdate function as volatile state
    let forceUpdate: Function = () => {}

    return {
      updateV2: (partial: Partial<typeof self>) => {
        let change = false
        Object.keys(partial).forEach(k => {
          if (self[k] !== partial[k]) {
            self[k] = partial[k]
            change = true
            if (k === 'text' && self.type === 'SUBPAGE') {
              const found = resolveIdentifier(node, store.binder, self.id)
              found?.update({ text: partial[k] })
            }
          }
        })
        if (!change && store.editorStore?.isOnCollabUpdate) return

        // TODO: TODO:
        self.update('editedAt', Date.now())
        // TODO: TODO: make sure it sends api update properly
        store.editorStore!.onEditorChange('updatedNodes', {
          ...getSnapshot(self),
          originalText: self.text,
          index: self.currentIndex
        })
      },
      afterCreate: () => {
        store.editorStore!.onEditorChange('updatedNodes', {
          ...getSnapshot(self),
          originalText: self.text,
          index: self.currentIndex
        })
        if (self.type === 'SUBPAGE') {
          setTimeout(() => {
            // TODO: TODO: shouldn't do this multiple time
            fromEditorToBinderSchema(
              store.editorStore?.schemaId!,
              store.editorStore?.nodes || []
            )
          })
        }
        onPatch(self, patch => {
          if (
            patch.op === 'remove' ||
            ['fileUploadProgress', 'updatedAt', 'editedAt', 'isNew'].includes(
              patch.path.substr(1)
            )
          ) {
            return
          }
          if (patch.path === '/type') {
            self.update('checked', false)
          }

          if (
            self.type === 'SUBPAGE' &&
            patch.op === 'replace' &&
            patch.path === '/text'
          ) {
            const found = resolveIdentifier(node, store.binder, self.id)
            found?.update({ text: patch.value })
          }
          self.update('editedAt', new Date().getTime())
        })
      },
      // TODO: TODO:
      pushNewChild: (node: INodeSnapshot | INode): any =>
        addNewChildren(
          self.children,
          'currentIndex' in node ? node.currentIndex : node.index,
          node
        ),
      // setter and caller for each node's forceUpdate function
      setForceUpdate(f: Function) {
        forceUpdate = f
      },
      callForceUpdate() {
        forceUpdate()
      },
      getParentSubpage() {
        const getParentFn = (node: INode | typeof self): INode | undefined => {
          try {
            const parent = getParentOfType(node, Node)
            if (parent.type === 'SUBPAGE') return parent
            else return getParentOfType(parent, Node)
          } catch (e) {
            return
          }
        }
        return getParentFn(self)
      }
    }
  })

const Target = types
  .model('SortableTreeTarget', {
    node: types.maybe(types.reference(Node)),
    index: 0,
    position: types.maybe(
      types.enumeration('TargetPosition', [
        'prevSibling',
        'nextSibling',
        'firstChild',
        'lastChild',
        'currentNode'
      ])
    )
  })
  .actions(self => ({
    update: (partial: ITargetSnapshot) => applySnapshot(self, partial)
  }))

const SortableTree = types.model('SortableTreeStore', {
  target: types.optional(Target, {})
})

export interface ISortableTreeStore extends Instance<typeof SortableTree> {}
export interface ISortableTreeSnapshot
  extends SnapshotIn<typeof SortableTree> {}

const EditorStoreFactory = (
  data: INodeSnapshot[],
  title: string,
  icon: string | null,
  onEditorChange: (
    key:
      | 'title'
      | 'focusedElementId'
      | 'updatedNodes'
      | 'caretPosition'
      | 'icon',
    value: string | INodeSnapshot | number
  ) => void,
  schemaId: string
) => {
  const Store = types
    .model('EditorStore', {
      title: types.optional(types.string, ''),
      icon: types.maybeNull(types.string),
      nodes: types.array(Node),
      selectedNode: types.safeReference(Node),
      focusedElementId: types.maybe(types.string),
      caretPosition: types.maybe(types.number),
      rootNode: types.safeReference(Node),
      fileView: types.maybe(types.string),
      sortableTree: types.optional(SortableTree, {}),
      topLevelSelections: types.array(types.safeReference(Node)),
      schemaId: types.string,
      dragHoverTarget: types.optional(types.string, '')
    })
    .volatile(self => ({
      newNodeId: '' as string | null,
      selectedElements: [] as HTMLElement[],
      selection: undefined as SelectionJs | undefined,
      isOnCollabUpdate: false,
      moving: false
    }))
    .views(self => ({
      get children() {
        return self.nodes
      },
      get isEmptySingleNode() {
        return (
          self.nodes.length === 1 &&
          !self.nodes[0].children.length &&
          !self.nodes[0].text &&
          !self.nodes[0].attachmentUrl &&
          self.nodes[0].type !== 'SUBPAGE'
        )
      },
      get selections() {
        const topSelectedElements = self.selectedElements.reduce<HTMLElement[]>(
          (acc, curr) => {
            if (
              self.selectedElements.every(s => !s.contains(curr) || s === curr)
            ) {
              acc.push(curr)
            }
            return acc
          },
          []
        )
        const els = topSelectedElements.reduce<HTMLElement[]>((acc, curr) => {
          acc = [
            ...acc,
            ...(curr.getElementsByClassName('node-wrapper') as any)
          ]
          return acc
        }, [])
        return els.map(e => {
          const id = e.id.replace('node-wrapper-', '')
          return resolveIdentifier(Node, self, id)
        })
      }
    }))
    .actions(self => ({
      update: <T extends keyof typeof self>(field: T, value: typeof self[T]) =>
        (self[field] = value),
      pushNewNode: (node: INodeSnapshot | INode) =>
        addNewChildren(
          self.nodes,
          'currentIndex' in node ? node.currentIndex : node.index,
          node
        ),
      detachNode: (node: INode): void => {
        detach(node)
        node.bulkUpdate({ originalText: node.text, index: node.currentIndex })
      },
      selectNode: (nodeId?: string, focusedElementId?: string) => {
        if (!nodeId && self.selectedNode) {
          document.getElementById(self.selectedNode.id)?.blur()
        }
        self.selectedNode = cast(nodeId as any)
        self.focusedElementId = focusedElementId || nodeId
      },
      setFileView: (address: string) => {
        self.fileView = address
      },
      replaceChildren: (children: INode[]) => self.nodes.replace(children),
      splice: (index: number, item: INode) => self.nodes.splice(index, 0, item),
      remove: (index: number) => self.nodes.splice(index, 1),
      pushChild: (child: INode) => self.nodes.splice(0, 0, child)
    }))
    .actions(self => ({
      updateTitle: (newTitle: string): void => {
        self.title = newTitle
        onEditorChange('title', newTitle)
        const found = resolveIdentifier(Schema, store.binder, self.schemaId)
        found?.update('title', newTitle)
      },
      onEditorChange,
      getNodeById: (nodeId: string): INode | undefined => {
        return resolveIdentifier(Node, self, nodeId)!
      },

      // ====================
      createChildNode: (
        father: string | INode = self.rootNode!,
        position: 'first' | 'last' = 'last',
        type: NodeTypeEnum = 'PARAGRAPH',
        text = ''
      ): INode => {
        if (father === 'root') {
          const index = position === 'last' ? self.nodes.length : 0
          const [newNode] = addNewChildrenV3(schemaId, self.nodes, index, {
            type,
            fatherId: father,
            originalText: text
          })
          return newNode
        } else {
          const fatherNode =
            typeof father === 'string'
              ? resolveIdentifier(Node, self, father)!
              : father
          const index = position === 'last' ? fatherNode.children.length : 0

          const [newNode] = addNewChildrenV3(
            schemaId,
            fatherNode.children,
            index,
            {
              type,
              fatherId: fatherNode.id,
              originalText: text
            }
          )
          return newNode
        }
      },
      createSiblingNodes: (
        direction: 'above' | 'below',
        nodes: NewNode[],
        inheritType: boolean = false
      ): INode[] | undefined => {
        if (!self.selectedNode) {
          if (
            document.activeElement?.getAttribute('element-type') !==
            'node-input'
          )
            return

          self.selectedNode = document.activeElement.id as any
        }

        if (!self.selectedNode) return

        const nextSiblings =
          self.selectedNode!.fatherId === 'root'
            ? self.nodes
            : getParent<INode>(self.selectedNode!, 2).children

        const currentIndex = (nextSiblings as INode[]).findIndex(
          s => s.id === self.selectedNode!.id
        )

        const startIndex =
          direction === 'above' ? currentIndex : currentIndex + 1

        const nodeMapping = (
          nodes: NewNode[],
          fatherId?: string
        ): NewNodeInput[] =>
          nodes.map<NewNodeInput>(n => ({
            type:
              n.type ||
              (inheritType ||
              ['BULLET_POINT', 'PARAGRAPH', 'CHECKBOX'].includes(
                self.selectedNode!.type
              )
                ? self.selectedNode!.type
                : 'PARAGRAPH'),
            originalText: n.text || '',
            fatherId: fatherId || '',
            ...pick(n, ['icon', 'language', 'checked', 'attachmentUrl']),
            children: n.children?.length ? nodeMapping(n.children) : []
          }))
        const newNodes = addNewChildrenV3(
          schemaId,
          nextSiblings,
          startIndex,
          ...nodeMapping(nodes, self.selectedNode!.fatherId)
        )

        return newNodes
      },
      addNewChildrenV3,
      deleteNode: (
        nodeOrId: string | INode,
        ignoreFirst: boolean = false
      ): void => {
        const node =
          typeof nodeOrId === 'string'
            ? resolveIdentifier(Node, self, nodeOrId)
            : nodeOrId

        if (!node) return

        const isSubpage = node.type === 'SUBPAGE'
        const editorStore = store.editorStore!

        if (
          node.fatherId === 'root' &&
          node.currentIndex === 0 &&
          self.nodes.length === 1
        ) {
          if (!ignoreFirst) {
            if (node.attachmentUrl) {
              deleteAttachment(node.id)
            }
            node.updateV2({ type: 'PARAGRAPH', text: '', attachmentUrl: '' })
            if (isSubpage) {
              fromEditorToBinderSchema(editorStore.schemaId, editorStore.nodes)
            }
            return
          }
        }
        setTimeout(() =>
          onEditorChange('updatedNodes', {
            ...getSnapshot(node),
            index: node.currentIndex,
            _destroy: true
          })
        )

        self.selectedNode = undefined
        // detach(node);
        addDeleteNodeId(node.id)
        const fatherId = node.fatherId
        destroy(node)

        const siblings: INode[] =
          fatherId === 'root'
            ? self.nodes
            : resolveIdentifier(Node, self, fatherId)!.children
        siblings.forEach((s, i) => s.updateV2({ currentIndex: i }))
        // fallback when we delete first node.
        if (self.nodes.length === 0) {
          addNewChildrenV3(schemaId, self.nodes, 0, {
            type: 'PARAGRAPH',
            fatherId: 'root',
            originalText: ''
          })
        }
        if (isSubpage) {
          fromEditorToBinderSchema(editorStore.schemaId, editorStore.nodes)
        }
      },
      changeFather: (
        direction: 'left' | 'right',
        expandNode?: (nodeId: string) => void,
        whichNode?: INode
      ): void => {
        let selectedNode = whichNode || self.selectedNode
        if (!selectedNode) {
          if (
            document.activeElement?.getAttribute('element-type') !==
            'node-input'
          )
            return

          self.selectedNode = document.activeElement.id as any
          selectedNode = self.selectedNode
          if (!selectedNode) return
        } else {
          const currentFather = resolveIdentifier(
            Node,
            self,
            selectedNode.fatherId
          )

          if (direction === 'right') {
            const currentSiblings = currentFather
              ? currentFather.children
              : self.nodes

            const nextFather = getSiblingNode(
              currentSiblings,
              selectedNode!,
              'previous'
            )

            if (!nextFather) {
              return
            } else {
              detach(selectedNode)
              if (expandNode) {
                expandNode(nextFather.id)
              }

              selectedNode.updateV2({
                index: nextFather.children.length,
                fatherId: nextFather.id,
                currentIndex: nextFather.children.length
              })

              currentSiblings.forEach((s: INode, i) =>
                s.updateV2({
                  currentIndex: i
                })
              )

              nextFather.update('collapsed', false)
              nextFather.children.push(selectedNode)
            }
          } else {
            if (
              !currentFather ||
              (self.rootNode && currentFather.id === self.rootNode.id)
            ) {
              return
            }

            const nextFather = resolveIdentifier(
              Node,
              self.nodes,
              currentFather.fatherId
            )

            const nextSiblings = nextFather ? nextFather.children : self.nodes
            const nextFatherId = nextFather ? nextFather.id : 'root'

            detach(selectedNode)

            selectedNode.updateV2({
              fatherId: nextFatherId,
              currentIndex: currentFather.currentIndex + 1
            })

            addNewChildren(
              nextSiblings,
              selectedNode.currentIndex,
              selectedNode
            )
          }
        }
      },
      selectRootNode: (nodeId?: string): void =>
        (self.rootNode = nodeId as any),
      toggleCollapseAll: (
        value: 'NONE' | 'ALL',
        toggleRootNode?: INode
      ): void => {
        let nodes
        if (toggleRootNode) {
          nodes = toggleRootNode.children
        } else {
          nodes = self.rootNode ? self.rootNode.children : self.nodes
        }
        walk(nodes, node => {
          const type = getType(node)
          if (type.name === 'NodeItem') {
            ;(node as INode).updateV2({ collapsed: value === 'ALL' })
          }
        })
      },
      clearSelections: (): void => {
        self.selectedElements.forEach(e => {
          const treeItem = e.closest<HTMLElement>('.treeItem')
          if (treeItem) {
            unhighlightEl(treeItem)
          }
        })
        self.selectedElements = []
        self.topLevelSelections = cast([])
      },
      removeSelection: (el: HTMLElement): void => {
        self.selectedElements = self.selectedElements.filter(
          i => i.id !== el.id
        )
        self.topLevelSelections = cast(
          self.topLevelSelections.reduce<string[]>((acc, curr) => {
            const itemId = el.id.replace('treeItem-', '')
            if (curr?.id !== itemId) acc.push(curr?.id!)
            return acc
          }, [])
        )
        unhighlightEl(el)
      },
      addSelection: (el: HTMLElement): void => {
        self.selectedElements.push(el)
        self.topLevelSelections.push(el.id.replace('treeItem-', ''))
        highlightEl(el)
      },
      selectAll: (): void => {
        self.topLevelSelections = cast([])
        self.selectedElements = []
        ;(self.rootNode ? self.rootNode.children : self.nodes).forEach(n => {
          self.topLevelSelections.push(n.id)
          const el = document.getElementById('treeItem-' + n.id)
          if (el) {
            self.selectedElements.push(el)
            highlightEl(el)
          }
        })
      }
    }))
    .actions(self => ({
      preAttachmentUpload: (): void => {
        let node: INode | undefined
        if (self.selectedNode) {
          if (self.selectedNode.attachmentUrl) {
            node = self.createSiblingNodes('below', [{}])![0]
          }
        } else {
          node = self.createChildNode('root', 'last', 'PARAGRAPH')
        }
        if (node) self.selectNode(node.id)
      }
    }))

  return Store.create({
    nodes: data,
    title,
    icon,
    schemaId
  })
}

export interface ITarget extends Instance<typeof Target> {}
export interface ITargetSnapshot extends SnapshotIn<typeof Target> {}
export interface INode extends Instance<typeof Node> {
  _destroy?: boolean
  isChild?: boolean
}
export interface INodeSnapshot extends SnapshotIn<typeof Node> {
  _destroy?: boolean
  isChild?: boolean
}
export interface IEditorStore
  extends Instance<ReturnType<typeof EditorStoreFactory>> {}

export interface IEditorStoreSnapshot
  extends SnapshotIn<ReturnType<typeof EditorStoreFactory>> {}

export default EditorStoreFactory
