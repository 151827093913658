import { pasteHtmlAtCaret } from './../../pages/SchemaPage/components/Editor/helpers/documents'
import { Instance, SnapshotIn, types, flow } from 'mobx-state-tree'
import fuzzysort from 'fuzzysort'
import constants from 'src/config/constants'
import store from 'src/store'
import * as path from 'path'
import {
  focusNode,
  getCaretPosition,
  getCaretData,
  setCaretPosition
} from 'src/pages/SchemaPage/components/Editor/helpers'

const itemHeight = constants.mention.itemHeight
const numVisible = constants.mention.numberOfVisibleItems

const Attachment = types.model('Attachment', {
  attachmentUrl: types.string,
  reference: types.string,
  schema: types.model('Schema', {
    id: types.string,
    isPrivate: types.boolean
  }),
  text: types.string,
  sanitizedText: types.string
})

const Mention = types
  .model('Mention', {
    data: types.array(Attachment),
    triggered: types.optional(types.boolean, false),
    triggerStartPosition: types.optional(types.number, 0),
    mentionPickerOpen: types.optional(types.boolean, false),
    filterText: types.optional(types.string, ''),
    selectedItem: types.optional(types.number, 0)
  })
  .volatile(self => ({
    setAnchorEl: (input: any) => {},
    ref: null as HTMLUListElement | null
  }))
  .actions(self => ({
    update: <T extends keyof typeof self>(field: T, value: typeof self[T]) =>
      (self[field] = value)
  }))
  .views(self => ({
    get filteredList(): Fuzzysort.KeyResults<IMentionAttachment> {
      if (self.filterText === '')
        return self.data
          .filter(
            attachment =>
              store.currentSchema!.isPrivate || !attachment.schema.isPrivate
          )
          .map(item => ({ obj: item })) as any
      return fuzzysort.go(
        self.filterText,
        self.data.filter(
          attachment =>
            store.currentSchema!.isPrivate || !attachment.schema.isPrivate
        ),
        {
          key: ['sanitizedText']
        }
      )
    }
  }))
  .actions(self => ({
    closeMentionPicker: () => {
      self.mentionPickerOpen = false
      self.triggered = false
      self.triggerStartPosition = 0
      self.filterText = ''
      self.selectedItem = 0
    },
    setTriggerStartPosition: (num: number) => {
      self.triggerStartPosition = num
    },
    setSelectedItem: (num: number) => {
      self.selectedItem = num
      if (!self.ref) return
      let currentScrollTop = self.ref.scrollTop
      if (
        !(
          num * itemHeight < currentScrollTop + itemHeight * numVisible &&
          num * itemHeight >= currentScrollTop
        ) &&
        self.ref
      ) {
        self.ref.scrollTop = itemHeight * (num - numVisible / 2 + 1)
      }
    }
  }))
  .actions(self => ({
    openMentionPicker: () => {
      const selection = window.getSelection()

      // Resets when the selection has a length of 0
      if (!selection) {
        self.closeMentionPicker()
        return
      }
      self.triggered = true
      const getBoundingClientRect = () =>
        selection.getRangeAt(0).getBoundingClientRect()

      self.mentionPickerOpen = true
      self.setAnchorEl({
        clientWidth: getBoundingClientRect().width,
        clientHeight: getBoundingClientRect().height,
        getBoundingClientRect
      } as any)
    },
    addSelectedMention: flow(function* (selected?: number) {
      if (store.editorStore && !store.editorStore.selectedNode) {
        const newNode = store.editorStore?.createChildNode(
          store.editorStore.rootNode || 'root',
          'last',
          'PARAGRAPH'
        )

        if (newNode) {
          yield new Promise(resolve =>
            setTimeout(() => {
              store.editorStore?.selectNode(newNode.id)
              focusNode(newNode.id)
              resolve()
            })
          )
        }
      }
      const selectedNode = store.editorStore?.selectedNode!
      focusNode(selectedNode.id!)

      self.triggered = false
      let chosenMention
      if (selected === undefined) {
        chosenMention = self.filteredList[self.selectedItem]
      } else {
        chosenMention = self.filteredList[selected]
      }

      if (!chosenMention) return
      Array.from(Array(self.filterText.length + 1)).forEach(() => {
        document.execCommand('delete')
      })
      const ext = path.extname(chosenMention.obj.attachmentUrl)
      const title =
        chosenMention.obj.sanitizedText || chosenMention.obj.attachmentUrl
      const value = title.length > 20 ? title.slice(0, 20) + '...' : title

      pasteHtmlAtCaret(
        `&nbsp;<a class="attachment-reference node-ref_${
          chosenMention.obj.reference
        }" file-ext="${
          ext.startsWith('.') ? ext.slice(1).toUpperCase() : ''
        }" original-schema-id="${
          chosenMention.obj.schema.id
        }" original-node-ref="${chosenMention.obj.reference}" href="${
          // TODO:
          chosenMention.obj.attachmentUrl
        }" value="${value}" title="${title}"></a>&nbsp;`
      )
      selectedNode.updateV2({
        text: document.getElementById(selectedNode.id)?.innerHTML
      })
      setTimeout(() => {
        const pos = getCaretPosition(document.getElementById(selectedNode.id)!)!
        var data = getCaretData(
          document.getElementById(selectedNode.id)!,
          pos + 2
        )
        setCaretPosition(data)
      })
      self.closeMentionPicker()
    })
  }))

export default Mention
export interface IMention extends Instance<typeof Mention> {}
export interface IMentionSnapshot extends SnapshotIn<typeof Mention> {}
export interface IMentionAttachment extends Instance<typeof Attachment> {}
