import { s3Prefix } from './../../../../../config/index'
import { INode } from '../editorStore'
import { isURL, focusAndPlaceCaretAtEnd } from '.'
import store from 'src/store'
import colors from 'src/config/colors'
import { v4 as uuid } from 'uuid'

// TODO: @Tran: nice to have tests for these

const nodeToHTML = (node: INode) => {
  let text = `<li 
  data-type="${node.type}" 
  ${node.language ? `data-language="${node.language}"` : ''}
  data-checked="${node.checked}"
  ${node.icon ? `data-icon="${node.icon}"` : ''}
  ${node.attachmentUrl ? `data-attachmenturl="${node.attachmentUrl}"` : ''}
  ><span>${
    node.attachmentUrl ? `<a href="${node.attachmentUrl}" schemaLink></a> ` : ''
  }${node.text}</span>`
  if (node.children?.length! > 0) {
    text += '<ul>'
    for (let child of node.children!) {
      text += `${nodeToHTML(child)}`
    }
    text += '</ul>'
  }
  text += `</li>`
  return text
}

export const toHtmlClipboard = (nodes: Array<INode>) =>
  `<ul id="schema-clipboard-${uuid()}">${nodes.reduce((sum, node) => {
    sum += nodeToHTML(node)
    return sum
  }, '')}</ul>`

export const toTextClipboard = (nodes: Array<INode>) =>
  nodes.reduce((acc, curr) => {
    let text = ''
    if (curr.attachmentUrl && !curr.attachmentUrl.startsWith(s3Prefix)) {
      text = curr.attachmentUrl + (curr.text?.length ? ` ${curr.text}` : '')
    } else {
      text = curr.text || ''
    }
    acc += text + '\n'
    return acc
  }, '')

export const createLoadingText = (content: string | null) => {
  return `<div contenteditable="false" class="loading-color">${content}</div>`
}

export const checkIfLastWordWasLink = (node: INode) => {
  let words = node.text
  words = words.replace(/&nbsp;/g, '')
  const n = words.split(' ')
  const lastWord = n[n.length - 1]
  if (isURL(lastWord)) {
    node.update(
      'text',
      words.replace(lastWord, `<a href="${lastWord}">${lastWord}</a>`)
    )
    node.callForceUpdate()
    focusAndPlaceCaretAtEnd(document.getElementById(node.id) as HTMLElement)
  }
}

export const toggleNodeSelection = (
  e: MouseEvent | React.MouseEvent<any>,
  treeItemEl: HTMLElement
) => {
  if (!store.editorStore) return

  e.preventDefault()
  e.stopPropagation()
  store.editorStore.selectNode(undefined)
  window.getSelection()?.empty()
  const selected = store.editorStore.selectedElements.find(
    i => i.id === treeItemEl.id
  )

  const contains = store.editorStore.selectedElements.find(i =>
    i.contains(treeItemEl)
  )

  const child = store.editorStore.selectedElements.find(i =>
    treeItemEl.contains(i)
  )

  if (selected) {
    store.editorStore.removeSelection(treeItemEl)
  } else if (contains) {
    store.editorStore.removeSelection(contains)
    store.editorStore.addSelection(treeItemEl)
  } else if (child) {
    store.editorStore.removeSelection(child)
    store.editorStore.addSelection(treeItemEl)
  } else {
    store.editorStore.addSelection(treeItemEl)
  }
}

export const highlightEl = (el: HTMLElement) => {
  el.style.background = colors.nodeHighlightBg
  // const checkedBoxEl = el.getElementsByClassName(
  //   'checked-box'
  // )[0] as HTMLElement;
  // checkedBoxEl.style.opacity = '0.6';
}

export const unhighlightEl = (el: HTMLElement) => {
  el.style.background = 'none'
  // const checkedBoxEl = el.getElementsByClassName(
  //   'checked-box'
  // )[0] as HTMLElement;
  // checkedBoxEl.style.opacity = '0';
}

export const isFormElement = (el: HTMLElement) =>
  ['INPUT', 'TEXTAREA'].includes(el.tagName) &&
  el.getAttribute('element-type') !== 'node-input'

export const validURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

export const pasteHtmlAtCaret = (html: string) => {
  var range
  if (window.getSelection) {
    // IE9 and non-IE
    const sel = window.getSelection()
    if (sel?.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()

      // Range.createContextualFragment() would be useful here but is
      // only relatively recently standardized and is not supported in
      // some browsers (IE9, for one)
      var el = document.createElement('div')
      el.innerHTML = html
      var frag = document.createDocumentFragment(),
        node,
        lastNode
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      range.insertNode(frag)

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  }
}
