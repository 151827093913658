import {
  types,
  Instance,
  SnapshotIn,
  IAnyModelType,
  flow,
  isAlive
} from 'mobx-state-tree'
import { google } from 'googleapis'
import { googleDriveFields } from 'src/api/googleDrive'

const GoogleDriveFile = types
  .model('GoogleDriveFile', {
    id: types.identifier,
    kind: types.string,
    mimeType: types.string,
    name: types.string,
    iconLink: types.string,
    thumbnailLink: types.maybeNull(types.string),
    webViewLink: types.string,
    children: types.array(types.late((): IAnyModelType => GoogleDriveFile))
  })
  .volatile(self => ({
    collapsed: true
  }))
  .views(self => ({
    get isFolder() {
      return self.mimeType === 'application/vnd.google-apps.folder'
    }
  }))
  .views(self => ({
    get folderChildren() {
      return self.children.filter(i => i.isFolder)
    },
    get fileChildren() {
      return self.children.filter(i => !i.isFolder)
    }
  }))
  .actions(self => ({
    update: <T extends keyof typeof self>(field: T, value: typeof self[T]) =>
      (self[field] = value),
    getChildren: flow(function* getChildren() {
      const res = yield google.drive('v3').files.list({
        q: `'${self.id}' in parents and trashed=false`,
        fields: googleDriveFields,
        orderBy: 'folder'
      })
      if (isAlive(self)) {
        self.children = res.data.files
      }
    })
  }))

export default GoogleDriveFile
export interface IGoogleDriveFile
  extends Omit<Instance<typeof GoogleDriveFile>, 'children'> {
  children: IGoogleDriveFile[]
}
export interface IGoogleDriveFileSnapshot
  extends Omit<SnapshotIn<typeof GoogleDriveFile>, 'children'> {
  children: IGoogleDriveFileSnapshot[]
}
