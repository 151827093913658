import { fromApiSchema } from './schema'
import { toast } from 'react-toastify'
import gql from 'graphql-tag'
import Toast from 'src/components/Toast'
import { getApolloClient } from 'src/config/apolloClient'

import { ApiSchema, nodeFields, schemaFields } from './gql'

export const createSharedSchema = async (
  schemaId: string,
  email: string,
  nodeRef?: string
) => {
  try {
    const client = await getApolloClient()
    const res = await client.mutate<{}>({
      mutation: gql`
        mutation createSharedSchema($input: CreateSharedSchemaInput!) {
          createSharedSchema(input: $input) {
            success
          }
        }
      `,
      variables: { input: { schemaId, email, nodeRef } }
    })
    return res.data
  } catch (e) {
    console.log('ERROR: createSharedSchema: ', e)
    return
  }
}

export interface ApiSharedSchema {
  id: string
  status: SharedSchemaStatus
  toUserEmail?: string | null
  toUserId?: string | null
  fromUserId: string
  recipientEmail?: string | null
  schema: ApiSchema
  sharedNode?: {
    id: string
    reference: string
    text: string
  }
}

export const allSharedSchemasFromUser = async (
  schemaId: string,
  nodeRef?: string
) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{
      allSharedSchemasFromUser: ApiSharedSchema[]
    }>({
      fetchPolicy: 'no-cache',
      query: gql`query allSharedSchemasFromUser($schemaId: String, $nodeRef: String) {
        allSharedSchemasFromUser(schemaId: $schemaId, nodeRef: $nodeRef) {
            id
            status
            toUserEmail
            toUserId
            recipientEmail
            fromUserId
            schema {
              ${schemaFields}
            }
          }
      }`,
      variables: { schemaId, nodeRef }
    })
    return res.data.allSharedSchemasFromUser.map(s => ({
      ...s,
      schema: fromApiSchema(s.schema)
    }))
  } catch (e) {
    console.error(e)
    toast.error(e.message)
    return []
  }
}

export const allSharedSchemasToUser = async () => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{
      allSharedSchemasToUser: ApiSharedSchema[]
    }>({
      fetchPolicy: 'no-cache',
      query: gql`query allSharedSchemasToUser {
        allSharedSchemasToUser {
            id
            status
            toUserEmail
            toUserId
            recipientEmail
            fromUserId
            schema {
              ${schemaFields}
              subpages {
                ${nodeFields}
                parentPage {
                  parentPageReference
                  relativePath
                }
              }
            }
            sharedNode {
              id
              reference
              text
            }
          }
      }`
    })
    return res.data.allSharedSchemasToUser.map(s => ({
      ...s,
      schema: { ...fromApiSchema(s.schema), sharedNode: s.sharedNode }
    }))
  } catch (e) {
    console.error(e)
    toast.error(e.message)
    return []
  }
}

export const updateSharedSchema = async (
  id: string,
  status: SharedSchemaStatus
) => {
  try {
    const client = await getApolloClient()
    await client.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation updateSharedSchema($input: UpdateSharedSchemaInput!) {
          updateSharedSchema(input: $input) {
            sharedSchema {
              id
              status
            }
          }
        }
      `,
      variables: { input: { id, status } }
    })
  } catch (e) {
    console.error(e)
    toast.error(e.message)
  }
}

export const deleteSharedSchema = async (id: string) => {
  try {
    const client = await getApolloClient()
    const res = await client.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation deleteSharedSchema($id: String!) {
          deleteSharedSchema(input: { id: $id }) {
            sharedSchema {
              id
            }
          }
        }
      `,
      variables: { id }
    })
    return res.data?.deleteSharedSchema.sharedSchema
  } catch (e) {
    console.error(e)
    toast.error(e.message)
  }
}
export const getSharedSchema = async (id: string) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{ sharedSchema: ApiSharedSchema }>({
      fetchPolicy: 'no-cache',
      query: gql`query sharedSchema($id: String!) {
        sharedSchema(id: $id) {
          id
          schema {
            ${schemaFields}
            nodes {
              ${nodeFields}
            }
          }
        }
      }`,
      variables: { id }
    })
    return res.data.sharedSchema.schema
  } catch (e) {
    console.log('ERROR: getSharedSchema: ', e)
    return
  }
}

export type SharedSchemaStatus = 'DISABLED' | 'READ' | 'READWRITE'

export const upsertShareableLink = async (
  schemaId: string,
  status?: SharedSchemaStatus,
  email?: string,
  nodeRef?: string
) => {
  try {
    const client = await getApolloClient()
    const res = await client.mutate<{
      upsertShareableLink: { link: string; status: SharedSchemaStatus }
    }>({
      mutation: gql`
        mutation upsertShareableLink($input: UpsertShareableLinkInput!) {
          upsertShareableLink(input: $input) {
            link
            status
          }
        }
      `,
      variables: { input: { schemaId, email, status, nodeRef } }
    })
    return res.data!.upsertShareableLink as {
      link: string
      status: SharedSchemaStatus
    }
  } catch (e) {
    console.log('ERROR: upsertShareableLink: ', e)
    Toast.error('Unable to get shareable link')
    return
  }
}

export const updateSharedSchemaPayload = async (id: string) => {
  const client = await getApolloClient()
  return client.subscribe({
    query: gql`
      subscription updateSharedSchemaSubscription($id: ID) {
        UpdateSharedSchemaPayload(id: $id) {
          payload {
            sharedSchema {
              id
              status
            }
          }
        }
      }
    `,
    variables: { id }
  })
}

export const upsertShareableLinkPayload = async (schemaId: string) => {
  const client = await getApolloClient()
  return client.subscribe({
    query: gql`
      subscription upsertShareableLinkSubscription($schemaId: String!) {
        UpsertShareableLinkPayload(schemaId: $schemaId) {
          payload {
            link
            status
          }
        }
      }
    `,
    variables: { schemaId }
  })
}

export const deleteSharedSchemaPayload = async (id: string) => {
  const client = await getApolloClient()
  return client.subscribe({
    query: gql`
      subscription deleteSharedSchemaSubscription($id: ID!) {
        DeleteSharedSchemaPayload(id: $id) {
          payload {
            sharedSchema {
              id
            }
          }
        }
      }
    `,
    variables: { id }
  })
}
