import { Auth } from 'aws-amplify'
import { Credentials } from 'google-auth-library'
import { google } from 'googleapis'
import { History } from 'history'
import { observer } from 'mobx-react'
import { resolveIdentifier } from 'mobx-state-tree'
import * as React from 'react'
import * as ReactGA from 'react-ga'
import { match, RouteComponentProps, withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { signOut } from 'src/api/auth'
import { updateUser } from 'src/api/user'
import {
  configureAmplify,
  googleOAuth2Client,
  googleOAuth2StorageKey,
  isDevelopment,
  LAST_OPEN_SCHEMA_ID_STORAGE_KEY,
  personalUserPool,
  storage
} from 'src/config'
import { initApolloClient } from 'src/config/apolloClient'
import { isElectron } from 'src/helpers/electron'
import SelectGoogleDriveStore from 'src/pages/SchemaPage/components/GoogleDrive/SelectGoogleDrive/store'
import store from 'src/store'
import Schema, { ISchema } from 'src/store/models/schema'

import API from '../../api'
import Loading from '../LoadingLogo'

export const createGoogleFolder = async () => {
  if (!store.user) return
  const folderId = await new Promise<string | undefined>(resolve =>
    google.drive('v3').files.create(
      {
        requestBody: {
          name: 'Schema.team',
          mimeType: 'application/vnd.google-apps.folder'
        }
      },
      async (err, res) => {
        if (err) {
          console.error(err)
          toast.error(err.message)
        }
        resolve(res?.data.id)
      }
    )
  )
  if (folderId) {
    SelectGoogleDriveStore.update('currentFolderId', folderId)
    store.user.update({ googleDriveFolderId: folderId })
    updateUser({
      id: store.user.id,
      googleDriveFolderId: folderId
    })
      .then(user => storage.setItem('schema_user', user))
      .catch(console.error)
  }
}

export const googleOauthTokenHandler = async (token: Credentials) => {
  googleOAuth2Client.setCredentials(token)
  if (store.isInitialized) {
    if (store.user && !store.user.googleDriveFolderId) {
      await createGoogleFolder()
    } else if (store.user?.googleDriveFolderId) {
      SelectGoogleDriveStore.update(
        'currentFolderId',
        store.user.googleDriveFolderId
      )
    }

    store.update('isSignedInGoogle', true)
  }
}

export interface IProps extends RouteComponentProps<{}> {
  children: (args: {
    logout: () => void
    history: History
    match: match
    checkLogin: () => Promise<void>
  }) => JSX.Element
}

export interface IState {
  mounted: boolean
}

class LoginController extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      mounted: false
    }
  }

  public async componentDidMount() {
    store.update('logout', this.logout)
    await this.checkLogin()
    window.addEventListener('storage', this.onStorage)

    const value = localStorage.getItem(googleOAuth2StorageKey)
    if (value) {
      googleOauthTokenHandler(JSON.parse(value))
    }

    googleOAuth2Client.on('tokens', tokens => {
      const value = localStorage.getItem(googleOAuth2StorageKey)
      if (!value) {
        localStorage.setItem(googleOAuth2StorageKey, JSON.stringify(tokens))
        return
      }
      const googleCredentials = JSON.parse(value) as Credentials
      if (tokens.refresh_token) {
        googleCredentials.refresh_token = tokens.refresh_token
      }
      googleCredentials.access_token = tokens.access_token
      googleCredentials.id_token = tokens.id_token
      googleCredentials.expiry_date = tokens.expiry_date
      localStorage.setItem(
        googleOAuth2StorageKey,
        JSON.stringify(googleCredentials)
      )
      googleOauthTokenHandler(googleCredentials)
    })
  }

  public componentWillUnmount() {
    window.removeEventListener('storage', this.onStorage)
  }

  private onStorage = (e: StorageEvent) => {
    if (e.key === googleOAuth2StorageKey && e.newValue) {
      googleOauthTokenHandler(JSON.parse(e.newValue))
    }
  }

  private checkLogin = async () => {
    const schemaUser = await storage.getItem<any>('schema_user')

    if (schemaUser) {
      await configureAmplify(schemaUser.workspace.cognitoUserPool)
      if (!isDevelopment)
        ReactGA.set({
          userId: schemaUser.id,
          email: schemaUser.email,
          workspaceName: schemaUser.workspace.name
        })
    }

    let cognitoUser

    try {
      cognitoUser = await Auth.currentAuthenticatedUser()
    } catch (err) {
      console.log('ERROR: checkLogin:', err)
    }

    if (cognitoUser) {
      // set user email and segment for crisp events
      if (window && (window as any).$crisp) {
        let segment =
          cognitoUser.pool.userPoolId === personalUserPool.id
            ? 'personal'
            : 'team'
        ;(window as any).$crisp.push([
          'set',
          'user:email',
          [cognitoUser.attributes.email]
        ])
        ;(window as any).$crisp.push(['set', 'session:segments', [[segment]]])
      }
    }

    if (schemaUser && cognitoUser) {
      await initApolloClient()

      await this.initialiseStore()

      if (
        this.props.location.pathname === '/' ||
        this.props.location.pathname === '/signin' ||
        this.props.location.pathname === '/signup' ||
        this.props.location.pathname.includes('/invitation/')
      ) {
        await this.navigateToSchemaPage()
      }
      return
    }

    if (cognitoUser) {
      await initApolloClient()

      const user = await API.user.getUserById(cognitoUser.username)

      if (!user) {
        return
      }

      await Promise.all([
        storage.setItem('schema_user', user),
        this.initialiseStore()
      ])

      await this.navigateToSchemaPage()
      return
    }

    if ((!schemaUser || !cognitoUser) && this.isPrivateRoutes()) {
      this.props.history.push('/')
    } else {
      this.setState({
        mounted: true
      })
    }
  }

  private initialiseStore = async () => {
    await store.initialize()
    this.setState({ mounted: true })
  }

  public async componentWillReceiveProps(_: IProps) {
    if (!store.isInitialized) {
      this.checkLogin()
    }
  }

  private navigateToSchemaPage = async () => {
    let toSchema: ISchema | undefined = undefined

    const lastOpenSchemaId = localStorage.getItem(
      LAST_OPEN_SCHEMA_ID_STORAGE_KEY
    )

    if (lastOpenSchemaId) {
      toSchema = resolveIdentifier(Schema, store, lastOpenSchemaId)
    }

    if (!toSchema) {
      toSchema =
        store.binder.teamSpace[0] ||
        store.binder.privateSpace[0] ||
        // FIXME: somehow store.binder is not updated
        (
          await API.schema.getSchemas(
            {
              archivedAt: { eq: null }
            },
            'createdAt_DESC',
            1
          )
        )[0]
    }

    if (toSchema) {
      this.props.history.push('/' + toSchema!.id)
    } else {
      const newSchema = await store.createSchema()
      store.binder.initialize()
      if (newSchema) {
        this.props.history.push(`/${newSchema.id}`)
      }
    }
  }

  private logout = () => {
    if (isElectron) {
      const ipc = (window as any).require('electron').ipcRenderer
      ipc.send('logout', {})
    }
    signOut()
    const googleToken = localStorage.getItem(googleOAuth2StorageKey)
    if (googleToken) {
      googleOAuth2Client.revokeToken(googleToken)
    }
    storage.clear()
    localStorage.clear()
    this.props.history.push('/')
  }

  private isPrivateRoutes = () =>
    this.props.location.pathname !== '/' &&
    this.props.location.pathname !== '/signin' &&
    this.props.location.pathname !== '/auth' &&
    this.props.location.pathname !== '/signup' &&
    isDevelopment &&
    !this.props.location.pathname.includes('/test-editor') &&
    !this.props.location.pathname.includes('/invitation/')

  public render() {
    if (
      !this.state.mounted ||
      (this.isPrivateRoutes() && !store.isInitialized)
    ) {
      return <Loading innerLoading={false} />
    }

    return React.Children.only(
      this.props.children({
        logout: this.logout,
        history: this.props.history,
        match: this.props.match,
        checkLogin: this.checkLogin
      })
    )
  }
}

export default withRouter(observer(LoginController))
