import { IpcRenderer } from 'electron'

import { s3Prefix } from '../config/index'
import { ISchema } from '../store/models/schema'

export const isElectron =
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

export const ipc: IpcRenderer | undefined = isElectron
  ? window.require('electron').ipcRenderer
  : undefined

export const sendTokenToElectron = (token: string, reInit: boolean) => {
  if (isElectron) {
    ipc?.send('token', { token, reInit })
  }
}

export const calibrateFiles = (schema: ISchema) => {
  if (!isElectron || !schema) return

  const undefinedSizes = schema.nodes.reduce<string[]>((acc, curr) => {
    if (curr.attachmentUrl?.startsWith(s3Prefix) && !curr.fileSize) {
      acc.push(curr.reference)
    }
    return acc
  }, [])
  ipc?.send('calibrate-files', undefinedSizes)
}
