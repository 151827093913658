import { toJS } from 'mobx'
import { cast } from 'mobx-state-tree'
import store from 'src/store'
import { INodeSnapshot } from 'src/store/models/node'

import { INode } from '../Editor/editorStore'

export const fromEditorToBinderSchema = (schemaId: string, _nodes: INode[]) => {
  if (!_nodes || !schemaId) return

  const nodes = toJS(_nodes)

  const schema = store.binder.findSchemaById(schemaId)
  if (schema) {
    schema.update('subpages', cast(fromEditorToBinder(nodes)))
  }
}

export const fromEditorToBinder = (nodes: INode[]) => {
  let subpages: INodeSnapshot[] = []
  const getSubpages = (editorNode: INode, parentPage?: INodeSnapshot) => {
    if (editorNode.type === 'SUBPAGE') {
      const node: INodeSnapshot = {
        id: editorNode.externalId,
        attachmentUrl: editorNode.attachmentUrl,
        checked: editorNode.checked,
        collapsed: editorNode.collapsed,
        createdAt: '',
        editedAt: editorNode.editedAt,
        fatherReference: editorNode.fatherId,
        icon: editorNode.icon,
        index: editorNode.currentIndex,
        language: editorNode.language,
        reference: editorNode.id,
        subpages: [],
        text: editorNode.text,
        type: editorNode.type,
        updatedAt: ''
      }

      if (editorNode.children.length) {
        for (const c of editorNode.children) {
          getSubpages(c, node)
        }
      }

      if (parentPage) {
        parentPage.subpages.push(node)
      } else {
        subpages.push(node)
      }
    } else {
      if (editorNode.children.length) {
        for (const c of editorNode.children) {
          getSubpages(c, parentPage)
        }
      }
    }
  }
  for (const editorNode of nodes) {
    getSubpages(editorNode)
  }

  return subpages
}
