import { toast } from 'react-toastify'
import { getApolloClient } from 'src/config/apolloClient'

import {
  allUsersQuery,
  ApiUser,
  createUserMutation,
  disableUserMutation,
  getUserByIdQuery,
  updateUserMutation
} from './gql'
import gql from 'graphql-tag'
import { serverHost } from 'src/config'

export const getUserById = async (id: string): Promise<any> => {
  const client = await getApolloClient()
  const res = await client.query<{ user: ApiUser }>({
    fetchPolicy: 'network-only',
    query: getUserByIdQuery,
    variables: { id }
  })
  return res.data!.user
}

export const getUserByEmail = async (email: string) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{
      getUserByEmail: Array<{ id: string; email: string }>
    }>({
      fetchPolicy: 'network-only',
      query: gql`
        query getUserByEmail($email: String!) {
          getUserByEmail(email: $email) {
            id
            email
          }
        }
      `,
      variables: { email }
    })
    return res.data.getUserByEmail
  } catch (e) {
    console.log('ERROR: getUserByEmail: ', e)
    return
  }
}

export const allUsers = async (where: any) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<{ allUsers: { user: ApiUser[] } }>({
      fetchPolicy: 'network-only',
      query: allUsersQuery,
      variables: { where }
    })
    return res.data!.allUsers.user
  } catch (err) {
    console.log('ERROR: allUsers: ', err)
    return []
  }
}

export const createUser = async (user: {
  email: string
  workspaceId: string
  fullName?: string
}) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: createUserMutation,
      variables: { input: { user } }
    })) as { data: { createUser: { user: ApiUser } } }
    return res.data.createUser.user
  } catch (err) {
    console.log('ERROR: createUser: ', err)
    return
  }
}

export const disableUser = async (userId: string) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: disableUserMutation,
      variables: { input: { userId } }
    })) as { data: { disableUser: { user: ApiUser } } }
    return res.data.disableUser.user
  } catch (err) {
    console.log('ERROR: disableUser: ', err)
    return
  }
}

export const updateUser = async (
  user: {
    id: string
  } & Partial<
    Pick<
      ApiUser,
      | 'fullName'
      | 'emailDigestEnabled'
      | 'tutorialSeen'
      | 'findOutAboutSchemaFrom'
      | 'schemaUsage'
      | 'describeWhatYouDo'
      | 'googleDriveFolderId'
      | 'showThumbnail'
    >
  >
) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: updateUserMutation,
      variables: { input: { user } }
    })) as { data: { updateUser: { user: ApiUser } } }
    return res.data.updateUser.user
  } catch (err) {
    console.log('ERROR: updateUser: ', err)
    return
  }
}

// TODO: remove if not needed
export const updateMeetingSchedule = async (
  sessionId: string,
  schedule: string
) => {
  try {
    await fetch(`${serverHost}/updateMeetingSchedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ sessionId, schedule })
    })
  } catch (e) {
    console.error(e)
  }
}

export const checkAvailability = async () => {
  try {
    const res = await fetch(`${serverHost}/checkAvailability`)
    return await res.json()
  } catch (e) {
    console.error(e)
    return false
  }
}

export const toggleAvailability = async (available: boolean) => {
  try {
    const client = await getApolloClient()
    await client.mutate({
      mutation: gql`
        mutation toggleAvailability($input: ToggleAvailabilityInput!) {
          toggleAvailability(input: $input) {
            success
          }
        }
      `,
      variables: { input: { available } }
    })
  } catch (e) {
    console.error(e)
    toast.error(e.message)
  }
}
