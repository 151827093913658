import { IAnyModelType, Instance, SnapshotIn, types } from 'mobx-state-tree'

import { NodeTypeEnum } from '../../api/gql'

export const nodeFactory = (useReference = false) =>
  types
    .model(useReference ? 'Node' : 'DummyNode', {
      id: types.maybe(types.number),
      reference: useReference ? types.identifier : types.string,
      type: types.enumeration<NodeTypeEnum>('NodeType', [
        'BULLET_POINT',
        'PARAGRAPH',
        'H1',
        'H2',
        'H3',
        'CHECKBOX',
        'CODE_SNIPPET',
        'SUBPAGE'
      ]),
      text: types.maybeNull(types.string),
      language: types.maybeNull(types.string),
      checked: types.boolean,
      fatherReference: types.maybeNull(types.string),
      index: types.number,
      collapsed: types.boolean,
      icon: types.maybeNull(types.string),
      attachmentUrl: types.maybeNull(types.string),
      fileSize: types.maybeNull(types.number),
      createdAt: types.maybeNull(types.string),
      updatedAt: types.maybeNull(types.string),
      editedAt: types.maybeNull(types.number),
      subpages: types.array(types.late((): IAnyModelType => node))
    })
    .actions(self => ({
      update: (partial: Partial<typeof self>): void =>
        Object.keys(partial).forEach(k => (self[k] = partial[k]))
    }))

export const DummyNode = nodeFactory()
const node = nodeFactory(true)

export default node
export interface INode extends Omit<Instance<typeof node>, 'subpages'> {
  subpages: INode[]
}
export interface INodeSnapshot
  extends Omit<SnapshotIn<typeof node>, 'subpages'> {
  subpages: INodeSnapshot[]
}
