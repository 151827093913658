import amplitude from 'amplitude-js/amplitude'
import * as _ from 'lodash'
import * as ReactGA from 'react-ga'
import { ApiUser } from 'src/api/gql'
import { isDevelopment, storage } from 'src/config'
import * as Sentry from '@sentry/browser'

let defaultEventProperties: IAmplitudeEventData = {}
let isUserIdSet = false

export const initAnalytics = () => {
  if (isDevelopment) {
    return
  }
  amplitude.getInstance().init('955e2f14244d5b7ad5a7ae8d20d917e7')
  ReactGA.initialize('UA-105373920-2')
}

export const setUserId = (userId: string) => {
  if (isDevelopment) {
    console.log('set amplitude userId to :', userId)
    return
  }
  if (!isUserIdSet) {
    ReactGA.set({ userId })
    isUserIdSet = true
  }
  amplitude.getInstance().setUserId(userId)
}

export const sendAmplitudeData = async (
  eventType: { category: string; name: string },
  eventProperties?: { [key: string]: string }
) => {
  if (isDevelopment) {
    console.log('Amplitude Event:', eventType)
  }

  if (!eventProperties) eventProperties = {}

  if (_.isEmpty(defaultEventProperties)) {
    const user: ApiUser = await storage.getItem('schema_user')
    if (user && user.id && user.email) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: user.fullName
      })
      setUserId(user.id)
      defaultEventProperties.workspace_name = user.workspace.name
      defaultEventProperties.email = user.email
      defaultEventProperties.workspace_id = user.workspace.id
      amplitude.getInstance().setUserProperties(defaultEventProperties)
    }
  }

  if (!isDevelopment) {
    amplitude.getInstance().logEvent(eventType.name, {
      ...eventProperties,
      category: eventType.category || 'General'
    })

    ReactGA.event({
      category: eventType.category || 'General',
      action: eventType.name
    })
  }
}

export const pageView = (pageName: string) => {
  if (isDevelopment) {
    console.log('PageView:', pageName)
    return
  }
  ReactGA.pageview(pageName)
}

export interface IAmplitudeEventData {
  user_id?: string
  storage?: number
  search_query?: string
  document_name?: string
  email?: string
  workspace_id?: string
  schema_id?: string
  workspace_name?: string
}

export const EventType = {
  OpenSchema: {
    category: 'Editor',
    name: 'Schema - Opened'
  },
  CreateSchema: {
    category: 'Editor',
    name: 'Schema - Created'
  },
  NodeToggle: {
    category: 'View actions',
    name: 'Node - Toggle'
  },
  EditorScroll: {
    category: 'View actions',
    name: 'Schema - Scroll'
  },
  NodeZoom: {
    category: 'View actions',
    name: 'Node - Zoom'
  },
  UpdateSchema: {
    category: 'Edit actions',
    name: 'Schema - Updated'
  },
  FileUpload: {
    category: 'File actions',
    name: 'Schema - File Attach'
  },
  FileDuplication: {
    category: 'File actions',
    name: 'Schema - File Duplication'
  },
  AttachUrl: {
    category: 'File actions',
    name: 'Schema - Link Attach'
  },
  DeleteFile: {
    category: 'File actions',
    name: 'Schema - Delete Attachment'
  },
  OpenPreview: {
    category: 'File actions',
    name: 'Schema - Open Preview'
  },
  ArchiveSchema: {
    category: 'Schema actions',
    name: 'Schema - Archived'
  },
  DeleteSchema: {
    category: 'Schema actions',
    name: 'Schema - Deleted'
  },
  Search: {
    category: 'Schema actions',
    name: 'Searched'
  },
  UnarchiveSchema: {
    category: 'Schema actions',
    name: 'Schema - Unarchived'
  },
  Signin: {
    category: 'Workspace actions',
    name: 'User - Sign in'
  },
  Signout: {
    category: 'Workspace actions',
    name: 'User - Sign out'
  },
  Singup: {
    category: 'Workspace actions',
    name: 'User - Sign up'
  },
  SurveyComplete: {
    category: 'Workspace actions',
    name: 'User - Completed Survey'
  },
  PrivateSwitch: {
    category: 'Workspace actions',
    name: 'Workspace - Private Switch'
  },
  UserInvited: {
    category: 'Workspace actions',
    name: 'Workspace - Invite User'
  },
  SignUpClick: {
    // @todo: this should get fired from button in landing page. update on landing page update
    category: 'Conversion',
    name: 'Conversion - Signup Click'
  },
  UserPasswordInput: {
    category: 'Conversion',
    name: 'Conversion - Username Password Input'
  },
  VerificationFinished: {
    category: 'Conversion',
    name: 'Conversion - Verification Finished'
  },
  LandingPageExperimentA: {
    category: 'Conversion',
    name: 'Conversion - Landing Page Version A',
    pageView: '/landing-page-a.html'
  },
  LandingPageExperimentB: {
    category: 'Conversion',
    name: 'Conversion - Landing Page Version B',
    pageView: '/landing-page-b.html'
  },
  GetEarlyAccess: {
    category: 'Conversion',
    name: 'Conversion - Get Early Access click',
    pageView: '/get-early-access.html'
  },
  JoinEarlyAccess: {
    category: 'Conversion',
    name: 'Conversion - Join Early Access',
    pageView: '/join-early-access.html'
  },
  PaySuccess: {
    category: 'Conversion',
    name: 'Conversion - Pay Success',
    pageView: '/pay-success.html'
  },
  GetEarlyAccessEmail: {
    category: 'Conversion',
    name: 'Conversion - Get Early Access Email click',
    pageView: '/get-early-access-email.html'
  },
  GetEarlyAccessName: {
    category: 'Conversion',
    name: 'Conversion - Get Early Access Name click',
    pageView: '/get-early-access-name.html'
  }
}
