import { Instance, types } from 'mobx-state-tree'

export const FlagModel = (
  name: string,
  defaultValue = false,
  sideEffect?: Function
) =>
  types.optional(
    types
      .model(name, {
        isOpen: types.optional(types.boolean, defaultValue)
      })
      .volatile(self => ({
        onAccept: undefined as undefined | Function
      }))
      .actions(self => ({
        update: (partial: Partial<typeof self>) =>
          Object.keys(partial).forEach(k => (self[k] = partial[k])),
        open: () => {
          self.isOpen = true
          if (sideEffect) sideEffect()
        },
        close: () => {
          self.isOpen = false
        },
        toggle: () => {
          self.isOpen = !self.isOpen
        }
      })),
    {}
  )

const FileView = types.optional(
  types
    .model('FileView', {
      isOpen: types.optional(types.boolean, false),
      address: types.maybeNull(types.string),
      nodeId: types.maybeNull(types.string),
      schemaId: types.maybeNull(types.string),
      downloadUrl: types.maybeNull(types.string),
      fullScreen: false
    })
    .actions(self => ({
      open: () => {
        self.isOpen = true
        UIStore.tutorialView.close()
      },
      close: () => {
        self.isOpen = false
        self.address = null
        self.fullScreen = false
        self.schemaId = null
        self.downloadUrl = null
      },
      toggle: () => {
        if (!self.isOpen) {
          UIStore.tutorialView.close()
        }
        self.isOpen = !self.isOpen
      },
      toggleFullScreen: () => {
        self.fullScreen = !self.fullScreen
      },
      setAddress: (
        nodeId: string,
        address: string,
        schemaId?: string,
        downloadUrl?: string
      ) => {
        self.nodeId = nodeId
        self.address = address
        self.schemaId = schemaId || null
        self.downloadUrl = downloadUrl || null
      }
    })),
  {}
)

const SnackBar = types.optional(
  types
    .model('Snackbar', {
      isOpen: false,
      message: '',
      variant: types.optional(
        types.enumeration('SnackbarVariant', [
          'success',
          'warning',
          'error',
          'info'
        ]),
        'info'
      )
    })
    .actions(self => ({
      close: () => {
        self.isOpen = false
        self.message = ''
        self.variant = 'info'
      },
      notify: (message: string, variant?: typeof self['variant']) => {
        self.message = message
        self.isOpen = true
        if (variant) {
          self.variant = variant
        }
      }
    })),
  {}
)

const UI = types.model('UI', {
  archivedSchemas: FlagModel('ArchivedSchemas', false),
  invitationModal: FlagModel('invitationModal'),
  shareSchemaModal: FlagModel('shareSchemaModal'),
  fileView: FileView,
  gdriveFileView: FileView,
  tutorialView: FlagModel('TutorialView'),
  hamburgerMenu: FlagModel('HamburgerMenu'),
  inlineToolbar: FlagModel('InlineToolbar'),
  languageSelection: FlagModel('LanguageSelection'),
  newGoogleDriveFolderModal: FlagModel('NewGoogleDriveFolderModal'),
  newUserModal: FlagModel('NewUserModal'),
  newWorkspaceModal: FlagModel('NewWorkspaceModal'),
  searchResults: FlagModel('SearchResults'),
  selectGoogleDriveFolderModal: FlagModel('SelectGooggleDriveFolderModal'),
  connectGDrive: FlagModel('ConnectGDrive', false),
  snackbar: SnackBar,
  workspaceModal: FlagModel('WorkspaceModal'),
  deleteNodesConfirmationModal: FlagModel('DeleteNodesConfirmationModal'),
  deleteBinderItemConfirmationModal: FlagModel(
    'deleteBinderItemConfirmationModal'
  ),
  archiveSchemaConfirmationModal: FlagModel('ArchiveSchemaConfiramtionModal'),
  signUpPromptModal: FlagModel('SignUpPromptModal', false),
  unverifiedUsersModal: FlagModel('UnverifiedUsersModal'),
  demographicQuestions: FlagModel('DemographicQuestions'),
  earlyAccessModal: FlagModel('EarlyAccessModal'),
  earlyAccessModalB: FlagModel('EarlyAccessModalB')
})

const UIStore = (window['UIStore'] = UI.create({}))

export default UIStore
export interface IUI extends Instance<typeof UI> {}
export interface IFlagModel extends Instance<ReturnType<typeof FlagModel>> {}
