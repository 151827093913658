import { getApolloClient } from 'src/config/apolloClient'

import {
  allEventQuery,
  allWebhooksQuery,
  ApiAllEventResponse,
  ApiAllWebhooksResponse,
  ApiCreateWebhookResponse,
  ApiDeleteWebhookResponse,
  ApiUpdateWebhookResponse,
  createWebhookMutation,
  deleteWebhookMutation,
  updateWebhookMutation
} from './gql'

export const allEvents = async () => {
  try {
    const client = await getApolloClient()
    const res = await client.query<ApiAllEventResponse>({
      query: allEventQuery
    })
    return res.data.allEvents.events
  } catch (err) {
    console.log('ERROR: allEvents: ', err)
    return []
  }
}

export const allWebhooks = async (channelId: string) => {
  try {
    const client = await getApolloClient()
    const res = await client.query<ApiAllWebhooksResponse>({
      query: allWebhooksQuery,
      variables: {
        where: { channelId: { eq: channelId } }
      }
    })
    return res.data.allWebhooks.webhooks
  } catch (err) {
    console.log('ERROR: allWebhooks: ', err)
    return []
  }
}

export const createWebhook = async (webhook: {
  title: string
  active: boolean
  url: string
  channelId: string
  events: string[]
}) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: createWebhookMutation,
      variables: { input: { webhook } }
    })) as { data: ApiCreateWebhookResponse }
    return res.data.createWebhook.webhook
  } catch (err) {
    console.log('ERROR: createWebhook: ', err)
    return
  }
}

export const updateWebhook = async (webhook: {
  id: number
  title?: string
  active?: boolean
  url?: string
  channelId?: string
  events?: string[]
}) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: updateWebhookMutation,
      variables: { input: { webhook } }
    })) as { data: ApiUpdateWebhookResponse }
    return res.data.updateWebhook.webhook
  } catch (err) {
    console.log('ERROR: updateWebhook: ', err)
    return
  }
}

export const deleteWebhook = async (id: number) => {
  try {
    const client = await getApolloClient()
    const res = (await client.mutate({
      mutation: deleteWebhookMutation,
      variables: { input: { id } }
    })) as { data: ApiDeleteWebhookResponse }
    return res.data.deleteWebhook.success
  } catch (err) {
    console.log('ERROR: deleteWebhook: ', err)
    return false
  }
}
