import { Auth } from 'aws-amplify'
import { getPathParts, IMSTArray } from 'mobx-state-tree'
import { CDNAddress, clientHost, serverHost } from 'src/config'
import colors from 'src/config/colors'
import constants from 'src/config/constants'

import { INode, INodeSnapshot, Node } from '../editorStore'

export const getNodeLevel = (node: INode) => {
  let level = 0
  if (node.fatherId === 'root') return level
  getPathParts(node).forEach(part => {
    if (part === 'children') {
      level++
    }
  })
  return level
}

export const getCaretCharacterOffsetWithin = (elementId: string) => {
  const element = document.getElementById(elementId) as any
  var caretOffset = 0
  if (element) {
    var doc = element.ownerDocument || element.document
    var win = doc.defaultView || doc.parentWindow
    var sel
    if (typeof win.getSelection != 'undefined') {
      sel = win.getSelection()
      if (sel.rangeCount > 0) {
        var range = win.getSelection().getRangeAt(0)
        var preCaretRange = range.cloneRange()
        preCaretRange.selectNodeContents(element)
        preCaretRange.setEnd(range.endContainer, range.endOffset)
        caretOffset = preCaretRange.toString().length
      }
    } else if ((sel = doc.selection) && sel.type != 'Control') {
      var textRange = sel.createRange()
      var preCaretTextRange = doc.body.createTextRange()
      preCaretTextRange.moveToElementText(element)
      preCaretTextRange.setEndPoint('EndToEnd', textRange)
      caretOffset = preCaretTextRange.text.length
    }
  }
  return caretOffset
}

export const splitTheBullet = (elementId: string) => {
  const preCaretRange = makeRangeFrom('start', elementId)
  const postCaretRange = makeRangeFrom('end', elementId)
  return [getRangeHTML(preCaretRange), getRangeHTML(postCaretRange)]
}

export const removeEmptyTags = (HTMLString: string) => {
  let elem = document.createElement('div')
  elem.innerHTML = HTMLString.trimStart()
  elem.childNodes!.forEach((child: HTMLElement) => {
    if (child.className?.includes('attachment-reference')) return
    child.nodeType === document.ELEMENT_NODE &&
      !child.innerText.trim() &&
      elem.removeChild(child)
  })
  return elem.innerHTML
}

const makeRangeFrom = (position: 'start' | 'end', elementId: string) => {
  let range
  let caret
  const element = document.getElementById(elementId) as any
  if (element) {
    const doc = element.ownerDocument || element.document
    const win = doc.defaultView || doc.parentWindow
    let sel
    if (typeof win.getSelection != 'undefined') {
      caret = win.getSelection().getRangeAt(0)
    } else if ((sel = doc.selection) && sel.type != 'Control') {
      caret = sel.createRange()
    }
  }
  range = caret.cloneRange()
  range.selectNodeContents(element)
  if (position === 'start') {
    range.setEnd(caret.endContainer, caret.endOffset)
  } else {
    range.setStart(caret.endContainer, caret.endOffset)
  }
  return range
}

const getRangeHTML = (range: Range) => {
  let preHTML = range.extractContents()
  let preTempElement = document.createElement('null')
  preTempElement.innerHTML = ''
  preTempElement.appendChild(preHTML)
  return preTempElement.innerHTML
}

export const navigateCursor = (
  direction: 'previous' | 'next',
  nodeId: string,
  forcePlaceCaretAtEnd?: boolean,
  fixFirstBulletPoint?: boolean
) => {
  const visibleNodes = document.getElementsByClassName(
    'node-input'
  ) as HTMLCollectionOf<HTMLDivElement>
  let targetNode: HTMLDivElement | undefined = undefined
  for (let i = 0; i < visibleNodes.length; i++) {
    if (direction === 'previous') {
      if (visibleNodes[i].id === nodeId && i > 0) {
        targetNode = visibleNodes[i - 1]
        break
      } else if (
        fixFirstBulletPoint &&
        visibleNodes[i].id === nodeId &&
        i === 0
      ) {
        targetNode = visibleNodes[0]
        break
      }
    } else {
      if (visibleNodes[i].id === nodeId && i < visibleNodes.length - 1) {
        if (forcePlaceCaretAtEnd) {
          targetNode = visibleNodes[i + 1]
          break
        } else {
          targetNode = visibleNodes[i + 1]
          break
        }
      }
    }
  }
  if (targetNode) {
    focusAndPlaceCaretAtEnd(targetNode)
  }

  return targetNode?.id
}

export const getSiblingNode = (
  siblings: IMSTArray<any>,
  selectedNode: INode,
  direction: 'previous' | 'next'
) => {
  const sibling = siblings.reduce((acc: INode, current: INode) => {
    if (
      (direction === 'previous' &&
        current.currentIndex < selectedNode.currentIndex &&
        (!acc || current.currentIndex > acc.currentIndex)) ||
      (direction === 'next' &&
        current.currentIndex > selectedNode.currentIndex &&
        (!acc || current.currentIndex < acc.currentIndex))
    ) {
      return current
    } else {
      return acc
    }
  }, undefined) as INode
  return sibling && sibling.id !== selectedNode.id ? sibling : undefined
}

// uncomment if it's needed
// export const getLastChild = (children: IMSTArray<INode>) => {
//   if (!children.length) {
//     return;
//   } else {
//     return children.reduce((acc, current) =>
//       current.index > acc.index ? current : acc
//     );
//   }
// };

export const focusAndPlaceCaretAtEnd = (el: HTMLElement | string) => {
  if (typeof el === 'string') {
    el = document.getElementById(el) as HTMLElement
  }
  if (!el) {
    return
  }
  if (el.getAttribute('element-type') === 'code-snippet') {
    const ace = el.getElementsByClassName('ace_text-input')
    ace[0]['focus']()
    return
  }
  el.focus()
  if (window.getSelection && document.createRange) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)
  } else if ((document.body as any).createTextRange) {
    const textRange = (document.body as any).createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}

export const focusNode = (nodeId: string) => {
  const node = document.getElementById(nodeId)
  if (node) {
    node.focus()
  }
}

export const toStyledText = (style: 'bold' | 'italic', event: any) => {
  event.preventDefault()
  document.execCommand(style, false, undefined)
}

export const getFatherFromPathTo = (
  nodes: INode[],
  pathTo: number[], // this path lead to the dragged item
  rootNode?: INode
): 'root' | INode => {
  if (pathTo.length === 1) {
    return rootNode || 'root'
  } else {
    let father = nodes[pathTo[0]]
    // - 1 so it get to the father
    for (let i = 1; i < pathTo.length - 1; i++) {
      father = father.children[pathTo[i]]
    }
    return father
  }
}

export const getFileExtenstion = (url: string) => {
  if (url.startsWith('https://docs.google.com/document')) return 'GDOC'
  else if (url.startsWith('https://docs.google.com/spreadsheets'))
    return 'GSHEET'
  else if (url.startsWith('https://docs.google.com/presentation'))
    return 'GSLIDE'
  else if (url.startsWith('https://drive.google.com/')) return 'GFILE'
  // else if (url.startsWith(CDNAddress! + '/' + S3Bucket!) || url.startsWith(CDNAddress! + S3Bucket!)) return 'SCHEMAFILE';
  else if (url.startsWith('https://trello.com')) return 'TRELLO'
  else if (url.startsWith(clientHost!)) return 'LINK'
  else if (!url.startsWith(CDNAddress!)) return 'LINK'
  return url.split('.').pop() ? url.split('.').pop()!.toUpperCase() : undefined
}

export const getFileTagColor = (fileExtension: string) => {
  switch (fileExtension) {
    case 'TRELLO': {
      return '#3377F3'
    }
    case 'PDF': {
      return '#E53C42'
    }
    case 'PSD': {
      return '#00C2FF'
    }
    case 'JPG':
    case 'JPEG':
    case 'GIF':
    case 'PNG': {
      return '#00BA9A'
    }
    case 'MKV':
    case 'MP4':
    case 'MPG':
    case 'M4V':
    case 'AVI':
    case 'MOV': {
      return '#FF5065'
    }
    case 'DOC':
    case 'DOCX':
    case 'GDOC': {
      return colors.googleDocs_fileTag
    }
    case 'XLS':
    case 'XLSX':
    case 'GSHEET': {
      return colors.googleSheets_fileTag
    }
    case 'GSLIDE': {
      return colors.googleSlides_fileTag
    }
    case 'GFILE': {
      return colors.blue_fileTag
    }
    case 'PPT':
    case 'PPTX': {
      return colors.fileTag
    }
    case 'LINK':
    default: {
      return '#82D1FF'
    }
  }
}

export const isMultilineElement = (elementId: string) => {
  let element = document.getElementById(elementId)!
  let elementHeight = element.offsetHeight - 2 * constants.nodes.padding
  let lineHeight = getLineHeight(element)
  return elementHeight >= lineHeight * 2
}

const getLineHeight = (element: HTMLElement) => {
  const computedStyles = window.getComputedStyle(element)
  return parseInt(computedStyles.getPropertyValue('line-height'))
}

export const isCaretAtLastLine = (elementId: string) => {
  let element = document.getElementById(elementId)!
  const elementBottomCoordination = element.getBoundingClientRect().bottom
  // for some weird reason element bottom differs few pixels with the actual bottom of the range
  return (
    Math.abs(elementBottomCoordination - getCaretCoordination().bottom) <
    3 * constants.nodes.padding
  )
}

export const isCaretAtFirstLine = (elementId: string) => {
  let element = document.getElementById(elementId)!
  const elementTopCoordination = element.getBoundingClientRect().top
  // for some weird reason element top differs few pixels with the actual top of the range
  return (
    Math.abs(elementTopCoordination - getCaretCoordination().top) <
    3 * constants.nodes.padding
  )
}

export const getCaretCoordination = () => {
  let bottom = 0
  let top = 0
  const sel = window.getSelection()
  if (sel?.rangeCount) {
    const range = sel.getRangeAt(0).cloneRange()
    if (range.getClientRects()) {
      range.collapse(true)
      const rect = range.getClientRects()[0]
      if (rect) {
        bottom = rect.bottom
        top = rect.top
      }
    }
  }
  return {
    bottom,
    top
  }
}

export const isURL = (str: string) => {
  return str.startsWith('http://') || str.startsWith('https://')
  // copy-pasted from https://stackoverflow.com/a/14582229/5284370
  // this commented part resolves any kind of link but we want to support only those starting with http or https
  // const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  //     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  // return pattern.test(str);
}

export const getURLTitle = async (
  _url: string
): Promise<string | undefined> => {
  let url = _url
  if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
    url = 'http://' + url
  }
  const cognitoUser = await Auth.currentAuthenticatedUser()
  // todo: deploy https://github.com/withspectrum/micro-open-graph service
  // this is currently hosted by Soorena we should change this ASAP
  let response = await fetch(`${serverHost}/metascraper`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${cognitoUser.signInUserSession.idToken.jwtToken}`
    },
    body: JSON.stringify({ url })
  })
  try {
    let json = await response.json()
    let title = json.title
    // TODO (Vehbi): I didn't like it, should be changed later.
    if (
      title == 'Google Sheets – create and edit spreadsheets online, for free.'
    )
      title = 'Private Google Sheets'
    else if (
      title == 'Google Docs – create and edit documents online, for free.'
    )
      title = 'Private Google Docs'
    else if (
      title == 'Google Slides – create and edit presentations online, for free.'
    )
      title = 'Private Google Slides'
    return title
  } catch (e) {
    console.log('connection to title resolver is broken : ', e)
    return
  }
}

export const getInnerURLFromResponse = async (url: string) => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  let response = await fetch(`${serverHost}/metascraper`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${cognitoUser.signInUserSession.idToken.jwtToken}`
    },
    body: JSON.stringify({ url })
  })
  try {
    let json = await response.json()
    let url = json.url
    return url
  } catch (e) {
    console.log('connection to title resolver is broken : ', e)
    return null
  }
}

export const getTextContent = (elementId: string | HTMLElement) => {
  let element
  if (typeof elementId === 'string') {
    element = document.getElementById(elementId)!
  } else {
    element = elementId
  }
  return element.textContent
}

export const getHostName = (url: string) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    return match[2]
  } else {
    return ''
  }
}

export const getCaretPosition = (el: HTMLElement) => {
  let caretOffset = 0
  if (typeof window.getSelection !== 'undefined' && el) {
    if (!window.getSelection()?.focusNode) {
      return
    }
    const range = window.getSelection()?.getRangeAt(0)!
    const selected = range.toString().length
    const preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(el)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    caretOffset = preCaretRange.toString().length - selected
  }
  return caretOffset
}

const getAllTextnodes = (el: HTMLElement) => {
  let node: Node | null
  const nodes = []
  const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false)
  while ((node = walk.nextNode())) nodes.push(node)
  return nodes
}

export const getCaretData = (el: HTMLElement, position: number) => {
  let node: Node | undefined
  const nodes = getAllTextnodes(el)
  for (let n = 0; n < nodes.length; n++) {
    if (position > nodes[n].nodeValue!.length && nodes[n + 1]) {
      // remove amount from the position, go to next node
      position -= nodes[n].nodeValue!.length
    } else {
      node = nodes[n]
      break
    }
  }
  // you'll need the node and the position (offset) to set the caret
  return { node: node, position: position }
}
// assume "component" is DOM element
// you may need to modify currentCaretPosition, see "Little Details"    section below
// setting the caret with this info  is also standard
export const setCaretPosition = (d: ReturnType<typeof getCaretData>) => {
  if (!d.node) {
    return
  }
  try {
    var sel = window.getSelection(),
      range = document.createRange()
    range.setStart(
      d.node,
      d.position > d.node['length'] ? d.node['length'] : d.position
    )
    range.collapse(true)
    sel?.removeAllRanges()
    sel?.addRange(range)
  } catch (e) {
    console.log(
      e.message,
      d.position,
      d.node['length'],
      d.position > d.node['length'] ? d.node['length'] : d.position,
      d.node
    )
  }
}

export const clearTextSelection = () => {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      // Chrome
      window.getSelection()?.empty()
    } else if (window.getSelection()?.removeAllRanges) {
      // Firefox
      window.getSelection()?.removeAllRanges()
    }
  } else if ((document as any).selection) {
    // IE?
    ;(document as any).selection.empty()
  }
}

export const addNewChildren = (
  siblings: IMSTArray<typeof Node>,
  startIndex: number,
  ...nodes: Array<INode | INodeSnapshot>
): void => {
  siblings.splice(startIndex, 0, ...nodes)
  siblings.forEach((s, i) => s.updateV2({ currentIndex: i }))
}

export const getUniqueTime = (arr: number[], time: number): number => {
  if (arr.includes(time)) {
    return getUniqueTime(arr, time + 1)
  } else {
    return time
  }
}

export type NewNodeInput = Pick<
  INodeSnapshot,
  | 'type'
  | 'originalText'
  | 'fatherId'
  | 'checked'
  | 'language'
  | 'icon'
  | 'attachmentUrl'
> & { children?: NewNodeInput[] }

export const addNewChildrenV3 = (
  schemaId: string,
  siblings: IMSTArray<typeof Node>,
  startIndex: number,
  ...nodes: NewNodeInput[]
): INode[] => {
  const newNodes = []
  let currentTimes: number[] = []
  const addChild = (n: NewNodeInput, idx: number, fatherId?: string) => {
    const now = Date.now()
    const time = getUniqueTime(currentTimes, now)
    currentTimes.push(time)

    const id = `${schemaId}-${time}`

    const index = startIndex + idx

    const newNodeData: INodeSnapshot = {
      ...n,
      id,
      fatherId: fatherId || n.fatherId,
      index,
      children: [],
      collapsed: false,
      editedAt: Date.now()
    }

    if (n.children?.length) {
      for (let i = 0; i < n.children.length; i++) {
        newNodeData.children!.push(addChild(n.children[i], i, newNodeData.id))
      }
    }

    return Node.create(newNodeData)
  }
  for (let i = 0; i < nodes.length; i++) {
    newNodes.push(addChild(nodes[i], i))
  }

  siblings.splice(startIndex, 0, ...newNodes)
  siblings.forEach((s, i) => s.updateV2({ currentIndex: i }))
  return newNodes
}
