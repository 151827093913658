import * as invitation from './invitation'
import * as node from './node'
import * as schema from './schema'
import * as sharedSchema from './sharedSchema'
import * as user from './user'
import * as webhook from './webhook'
import * as workspace from './workspace'
import * as statistic from './statistic'

export default {
  invitation,
  node,
  schema,
  sharedSchema,
  user,
  webhook,
  workspace,
  statistic
}
