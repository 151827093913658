import './loading.css'

import * as React from 'react'

import spinner from '../../styles/icons/loader.svg'
import schemaLogo from '../../styles/icons/logo_l.png'

interface Props {
  innerLoading: boolean
}

const Loading = (props: Props) => (
  <div id="loading" className={`loading${props.innerLoading ? ' inner' : ''}`}>
    <img alt="Schema Logo" className="logo" src={schemaLogo} />
    <h1>Schema</h1>
    <h2>
      Organise, Share and <span>Learn</span>
    </h2>
    <h3>
      <img alt="Loading" className="loader" src={spinner} />
    </h3>
  </div>
)

export default Loading
