import { isMobile } from 'react-device-detect'
import { ApiNode } from 'src/api/gql'
import { INodeSnapshot as IEditorNodeSnapshot } from 'src/pages/SchemaPage/components/Editor/editorStore'
import { INodeSnapshot } from 'src/store/models/node'

// TODO: write unit test for this

export const fromApiToEditorNodes = (
  nodes: Array<ApiNode | INodeSnapshot>
): IEditorNodeSnapshot[] =>
  nodes.map(node => ({
    id: node.reference,
    index: node.index,
    type: node.type,
    originalText: node.text || '',
    checked: node.checked,
    language: node.language,
    // collapse every thing by default
    collapsed: true, //  node.collapsed,
    fatherId: node.fatherReference || 'root',
    externalId: node.id,
    icon: node.icon,
    fileSize: node.fileSize,
    attachmentUrl: node.attachmentUrl,
    editedAt: node.editedAt,
    children: []
  }))

export const fromEditorToApiNodes = (nodes: IEditorNodeSnapshot[]) =>
  nodes.map(node => ({
    id: node.externalId,
    reference: node.id,
    type: node.type,
    text: node.originalText,
    checked: node.checked!,
    language: node.language,
    fatherReference: node.fatherId,
    index: node.index, // TODO: TODO:
    collapsed: node.collapsed,
    icon: node.icon,
    fileSize: node.fileSize,
    attachmentUrl: node.attachmentUrl,
    editedAt: node.editedAt,
    _destroy: node._destroy
  }))

export const nodesMapping = (
  nodes: IEditorNodeSnapshot[],
  isPartial?: boolean
) => {
  const defaultCollapsedGroups: string[] = []
  const badData: IEditorNodeSnapshot[] = []

  nodes.forEach(node => {
    if (node.collapsed || isMobile) {
      defaultCollapsedGroups.push(node.id)
    }
    if (node.fatherId && node.fatherId !== 'root') {
      const father = nodes.find(item => item.id === node.fatherId)!
      if (father) {
        ;(father.children as IEditorNodeSnapshot[]).push(node)
        node.isChild = true
      } else if (!isPartial) {
        // TODO: handle this
        // badData.push({ ...node, _destroy: true })
      }
    }
  })
  nodes.forEach(node => {
    if (Array.isArray(node.children)) {
      node.children.sort((a: any, b: any) => a.index - b.index)
    }
  })
  const nestableList = nodes
    .filter(node => !node.isChild && (isPartial || node.fatherId === 'root'))
    .sort((a, b) => a.index - b.index)

  return { nestableList, defaultCollapsedGroups, badData }
}

export const fromApiToEditorNodesMapping = (nodesData: INodeSnapshot[]) => {
  const nodes = fromApiToEditorNodes(nodesData)
  return nodesMapping(nodes)
}
